import React, { useState } from "react";
import axios from "axios";

export default function ReportBedsCMM() {
    const [loading, setLoading] = useState(false);

    const handleExport = async () => {
        setLoading(true); // Set loading to true while the request is in progress

        try {
            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}reports/export_bedscmm`,
                responseType: "blob", // Ensure the response is treated as a file blob
                data: {}, // Add additional payload if required
            });

            const href = URL.createObjectURL(response.data);

            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "BedsCMM.xlsx"); // Set the filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link); // Clean up the DOM
            URL.revokeObjectURL(href); // Revoke the blob URL
        } catch (error) {
            console.error("Error exporting BedsCMM report:", error);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
            <h1>Export Beds (checking in, moved in, mda onboarding)</h1>
            <div className="p-4">
                <button
                    onClick={handleExport}
                    className={
                        "relative justify-center inline-flex text-white uppercase items-center px-4 py-2 rounded-lg border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                    }
                    disabled={loading} // Disable button while loading
                >
                    {loading ? "Exporting..." : "Export Beds (checking in, moved in, mda onboarding)"}
                </button>
            </div>
        </div>
    );
}
