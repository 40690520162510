import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import {faUserPlus} from "@fortawesome/pro-regular-svg-icons";
import {toast} from "react-toastify";
import {openModal} from "../../store/modal/modalReducer";
import NotificationToast from "../Helpers/NotificationToast";
import CustomButton from "../Helpers/CustomButton";
import moment from "moment/moment";

export default function GridPayments({setDoc, db}) {
	const [loading, setLoading] = useState(true);
	const {current_staff} = useSelector((state) => state.auth);
	const {current_booking} = useSelector((state) => state.booking);
	const [banks, setBanks] = useState([]);
	const [allPayments, setAllPayments] = useState([]);
	const [fetchPayments, setFetchPayments] = useState(true);
	const dispatch = useDispatch();
	const [paymentFiles, setPaymentFiles] = useState([]);
	const [refunds, setRefunds] = useState([]);
	const paymentModules = [
		{label: "Refund", value: "Refund"},
		{label: "Booking Fee", value: "Booking Fee"},
		{label: "WiFi", value: "WiFi"},
		{label: "Building", value: "Building"},
		{label: "Guest", value: "Guest"},
		{label: "RAA", value: "RAA"},
		{label: "Monthly Rent", value: "Monthly Rent"},
	];
	const paymentVendors = [
		{label: "PayFast", value: 2},
	];
	const [showSendPayment, setShowSendPayment] = useState(false);
	const [selectedPaymentModule, setSelectedPaymentModule] = useState(null);
	const [selectedPaymentVendor, setSelectedPaymentVendor] = useState(null);
	const [amountToPay, setAmountToPay] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	// Separate payments into refunds and other payments
	const refundPayments = allPayments?.filter(payment => payment.payment_module === "Refund") || [];
	const otherPayments = allPayments?.filter(payment => payment.payment_module !== "Refund") || [];

	useEffect(() => {
		if (banks.length === 0) {
			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}paystack_banks`,
			}).then((response) => {
				let bank_data = response.data;
				if (bank_data.status === true) {
					setBanks(bank_data.data);
				}
			});
		}

		if (fetchPayments === true) {
			setFetchPayments(false);
			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}get_payments/${current_booking?.user.id}`,
			}).then((response) => {
				setAllPayments(response.data);
			});
		}

		if (refunds.length === 0) {
			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}get_refunds/${current_booking?.user.id}`,
			}).then((response) => {
				setRefunds(response.data);
			});
		}

		let Payments = current_booking?.user?.user_payment;
		Payments.sort((a, b) => (moment(a.created_at) > moment(b.created_at) ? -1 : 1));
		setAllPayments(Payments);
	}, [fetchPayments, current_booking]);

	const PaymentCard = ({payment}) => (
		<div className="bg-white shadow-sm rounded-lg p-4 mb-4 border border-gray-200 hover:shadow-md transition-all duration-200">
			<div className="flex flex-col space-y-2">
				{payment.transaction_result !== null ? (
					JSON.parse(payment.transaction_result).status === true ||
					JSON.parse(payment.transaction_result).Status === "Complete" ||
					JSON.parse(payment.transaction_result).payment_status === "COMPLETE" ? (
						<div className="flex items-center justify-center bg-green-50 py-2 rounded-md">
							<span className="text-green-600 font-medium">Payment Successful</span>
						</div>
					) : (
						<div className="flex items-center justify-center bg-red-50 py-2 rounded-md">
                            <span className="text-red-600 font-medium">
                                Payment Failed - {JSON.parse(payment.transaction_result).Status}
                            </span>
						</div>
					)
				) : (
					payment.status !== null ? (
						<div className="flex items-center justify-center bg-orange-50 py-2 rounded-md">
							<span className="text-orange-600 font-medium">{payment.status}</span>
						</div>
					) : (
						<div className="flex items-center justify-center bg-red-50 py-2 rounded-md">
							<span className="text-red-600 font-medium">Payment Failed - Payment Abandoned</span>
						</div>
					)
				)}
				<div className="text-center space-y-1">
					<p className="text-gray-600">{moment(payment.created_at).format("DD MMMM yyyy HH:mm")}</p>
					<p className="text-gray-800">{payment.name}</p>
					<p className="text-lg font-bold text-primary">R {(parseFloat(payment.amount) / 100).toFixed(2)}</p>
					<p className="text-gray-700">Reason: {payment.reason}</p>
					<p className="text-gray-700">Module: {payment.payment_module}</p>
					{payment.booking_charge_id > 0 && (<p className="text-gray-700">GTE: {payment.booking_charge?.status === 1 ? "Processed" : "Pending"}</p>)}
					{payment.booking_charge_id > 0 && payment.booking_charge?.status === 1 && (<p className="text-gray-700">Batch: {payment.booking_charge?.batch_no}</p>)}
				</div>
			</div>
		</div>
	);

	const BankingInfo = () => (
		<div className="bg-white shadow-sm rounded-lg p-6 mb-6 border border-gray-200">
			<h3 className="text-lg font-semibold mb-4">Banking Information</h3>
			<div className="grid grid-cols-2 gap-4">
				<div>
					<p className="text-gray-600">Bank</p>
					<p className="font-medium">{current_booking?.user?.profile?.banking_bank}</p>
				</div>
				<div>
					<p className="text-gray-600">Account Type</p>
					<p className="font-medium">{current_booking?.user?.profile?.banking_account_type}</p>
				</div>
				<div>
					<p className="text-gray-600">Branch</p>
					<p className="font-medium">{current_booking?.user?.profile?.banking_branch}</p>
				</div>
				<div>
					<p className="text-gray-600">Branch Code</p>
					<p className="font-medium">{current_booking?.user?.profile?.banking_branch_code}</p>
				</div>
				<div>
					<p className="text-gray-600">Account Number</p>
					<p className="font-medium">{current_booking?.user?.profile?.banking_account_number}</p>
				</div>
				<div>
					<p className="text-gray-600">Account Holder</p>
					<p className="font-medium">{current_booking?.user?.profile?.banking_account_holder}</p>
				</div>
			</div>
		</div>
	);

	async function new_recipient() {
		if (current_booking?.user.recipient_code === null) {
			await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_URL}create_recipient`,
				data: {booking_id: current_booking.id},
			})
				.then((response) => {
					if (response.data[0] === "PAYMENT FAILED") {
						toast(<NotificationToast title={"Receipient Failed"} message={response.data[1]} list={true}
												 type="error"/>);
					} else {
						dispatch(
							openModal({
								modal_type: "ModalSinglePayment",
								modal_props: {setFetchPayments: setFetchPayments},
							})
						);
					}
				})
				.catch((error) => {
					alert("Recipient failed");
				});
		} else {
			dispatch(openModal({modal_type: "ModalSinglePayment", modal_props: {setFetchPayments: setFetchPayments}}));
		}
	}

	const submitPayment = () => {
		if (!selectedPaymentModule || !selectedPaymentVendor || !amountToPay) {
			alert("Please fill out all fields before submitting.");
			return;
		}

		setIsSubmitting(true);

		axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}send_payment`,
			data: {
				payment_module: selectedPaymentModule,
				payment_vendor: selectedPaymentVendor,
				amount: amountToPay,
				booking_id: current_booking.id,
				user_id: current_booking?.user?.id,
			},
		})
			.then((response) => {
				if (response.data.message === "done") {
					setShowSendPayment(false);
					setSelectedPaymentModule(null);
					setSelectedPaymentVendor(null);
					setAmountToPay(null);
					setFetchPayments(true);
					toast.success("Payment submitted successfully!");
				}
			})
			.catch((error) => {
				console.error("Payment Submission Failed", error);
				toast.error("Payment submission failed. Please try again.");
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	return (
		<div className="h-96 overflow-y-auto scroll-smooth px-4">
			<div className="space-y-6">
				{/* Refunds & Banking Section */}
				<section>
					{/* Banking Information */}
					{current_booking?.user?.profile?.banking_account_number ? (
						<BankingInfo />
					) : (
						<p className="text-center text-gray-500 pb-4">Bank Information Not Loaded</p>
					)}

					<h4 className="text-md font-medium mb-3">Refund Payments</h4>

					{/* Send Refund Button */}
					{((process.env.REACT_APP_COLOUR === "UC" &&
							(current_staff.id == "291" || current_staff.id == "1" || current_staff.id == "14102")) ||
						(process.env.REACT_APP_COLOUR === "QL" &&
							(current_staff.id == "294" || current_staff.id == "297" || current_staff.id == "291" ||
								current_staff.id == "8684" || current_staff.id == "1"))) && (
						<CustomButton
							text={"Send Refund"}
							styling="w-full mt-2"
							icon={faUserPlus}
							onClick={() => new_recipient()}
							loadingtext="Loading..."
						/>
					)}

					{/* Refund Payments */}
					{refundPayments.length > 0 && (
						<div className="my-6">

							{refundPayments.map((payment, index) => (
								<PaymentCard key={index} payment={payment} />
							))}
						</div>
					)}
				</section>

				{/* Other Payments Section */}
				<section>
					<h3 className="text-lg font-semibold mb-4">Incoming Payments</h3>

					{/* Payment Form */}
					{process.env.REACT_APP_COLOUR === "UC" && (
						<div className="mb-6">
							<button
								className="bg-primary w-full text-center text-white uppercase px-4 py-2 rounded-lg text-sm focus:z-10 focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
								onClick={() => setShowSendPayment(!showSendPayment)}
							>
								{showSendPayment ? "Hide Payment Form" : "Request Payment"}
							</button>

							{showSendPayment && (
								<div className="bg-white shadow-sm rounded-lg p-6 border border-gray-200">
									<div className="space-y-4">
										<div>
											<label htmlFor="paymentModule" className="block text-sm font-medium text-gray-700 mb-1">
												Payment Module
											</label>
											<select
												id="paymentModule"
												className="w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
												value={selectedPaymentModule || ""}
												onChange={(e) => setSelectedPaymentModule(e.target.value)}
											>
												<option value="" disabled>Select a payment module</option>
												{paymentModules.map((module) => (
													<option key={module.value} value={module.value}>
														{module.label}
													</option>
												))}
											</select>
										</div>

										<div>
											<label htmlFor="paymentVendor" className="block text-sm font-medium text-gray-700 mb-1">
												Payment Vendor
											</label>
											<select
												id="paymentVendor"
												className="w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
												value={selectedPaymentVendor || ""}
												onChange={(e) => setSelectedPaymentVendor(e.target.value)}
											>
												<option value="" disabled>Select a payment vendor</option>
												{paymentVendors.map((vendor) => (
													<option key={vendor.value} value={vendor.value}>
														{vendor.label}
													</option>
												))}
											</select>
										</div>

										<div>
											<label htmlFor="amountToPay" className="block text-sm font-medium text-gray-700 mb-1">
												Amount to Pay
											</label>
											<input
												type="text"
												id="amountToPay"
												className="w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
												placeholder="Enter amount"
												value={amountToPay || ""}
												onChange={(e) => setAmountToPay(e.target.value)}
											/>
										</div>

										<button
											className="w-full bg-primary text-white py-2 rounded-lg font-medium hover:bg-primary_hover transition-all duration-200 disabled:opacity-50"
											onClick={submitPayment}
											disabled={isSubmitting}
										>
											{isSubmitting ? "Submitting..." : "Submit Payment"}
										</button>
									</div>
								</div>
							)}
						</div>
					)}

					{/* Other Payments List */}
					{otherPayments.map((payment, index) => (
						<PaymentCard key={index} payment={payment} />
					))}
				</section>

				{/* Internal Refund Checklist Section - Moved to bottom */}
				<section className="mt-8">
					<h3 className="text-lg font-semibold mb-4">Internal Refund Checklist</h3>

					<button
						className="w-full bg-primary text-white py-3 rounded-lg font-medium hover:bg-primary_hover transition-all duration-200 mb-6 text-sm"
						onClick={() =>
							dispatch(
								openModal({
									modal_type: "ModalRefund",
									modal_props: {current_booking},
								})
							)
						}
					>
						Create Internal Refund Checklist
					</button>

					{/* Refund Checklists */}
					<div className="mb-6">
						<h4 className="text-md font-medium mb-3">Existing Checklists</h4>
						{refunds?.map((refund, index) => (
							<div
								key={index}
								className="bg-white shadow-sm rounded-lg p-4 mb-3 border border-gray-200 cursor-pointer hover:shadow-md transition-all duration-200"
								onClick={() =>
									dispatch(
										openModal({
											modal_type: "ModalViewFiles",
											modal_props: {
												files: [{id: refund.file_id}],
											},
										})
									)
								}
							>
								<p className="text-gray-600">
									{moment(refund.created_at).format("yyyy-MM-DD HH:mm")}
								</p>
							</div>
						))}
					</div>
				</section>
			</div>
		</div>
	);
}