import React, { Fragment, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { closeModal } from "../../store/modal/modalReducer";
import { getBooking } from "../../store/booking/bookingActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import reactSelect from "react-select";

export default function ModalSearch(props) {
	const dispatch = useDispatch();
	const [open] = useState(true);
	const [searchResults, setSearchResults] = useState([]);
	const [searching, setSearching] = useState(false);
	const searchInput = useRef(null);
	const [searchCollections, setSearchCollections] = useState(false);
	const [searchRenewals, setSearchRenewals] = useState(false);
	const { current_staff } = useSelector((state) => state.auth);
	const { settings } = useSelector((state) => state.settings);

	async function searchFrm(e) {
		if (e.code === "Enter" || e === false) {
			setSearching(true);
			await axios
				.post(`${process.env.REACT_APP_API_URL}searchBookings`, {
					search: searchInput.current.value,
					renewals: searchRenewals,
				})
				.then((response) => {
					if (searchCollections === true) {
						let allresults = response.data;
						let newResults = [];
						allresults.forEach(function (r) {
							if (r.location === "Residents") {
								newResults.push(r);
							}
						});
						setSearchResults(newResults);
					} else if (searchRenewals === true) {
						let allresults = response.data;
						let newResults = [];
						allresults.forEach(function (r) {
							if (r.year_id === settings.year_id) {
								newResults.push(r);
							}
						});
						setSearchResults(newResults);
					} else {
						let allresults = response.data;
						let newResults = [];
						allresults.forEach(function (r) {
							if (
								current_staff.restrict_views === 0 ||
								(current_staff.restrict_views === 1 &&
									(r.tag === 4 || r.tag === 21))
							) {
								newResults.push(r);
							}
						});
						setSearchResults(newResults);
					}
					setSearching(false);
				})
				.catch((error) => {
					setSearchResults([]);
					setSearching(false);
				});
		}
	}

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-50 inset-0 overflow-y-auto"
				open={open}
				onClose={() => dispatch(closeModal())}
			>
				<ErrorBoundary>
					<div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block align-bottom bg-white rounded-lg px-2 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-7xl sm:p-6">
								<div className="mt-3 text-center sm:mt-0 sm:text-left">
									<Dialog.Title as="h1" className="text-lg font-medium text-gray-900">
										<div>
											<div className="flex flex-col sm:flex-row justify-between gap-2 sm:items-center">
												<div className="grow">
													<label className="block text-sm font-medium mb-2 text-gray-700">
														Search
													</label>
												</div>
												{current_staff.restrict_views === 0 && (
													<div className="flex gap-4">
														<label className="flex items-center text-sm font-medium text-gray-700">
															<input
																type="checkbox"
																onChange={(e) => setSearchCollections(e.target.checked)}
																className="mr-2"
															/>
															Collections
														</label>
														{/* Renewals checkbox commented out as in original code */}
													</div>
												)}
											</div>

											<div className="mt-1 flex flex-col sm:flex-row gap-2">
												<input
													className="block w-full font-light border border-gray-300 shadow-sm py-2 px-2 rounded-md focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary"
													ref={searchInput}
													placeholder="Type something to search"
													onKeyUp={(e) => searchFrm(e)}
												/>

												<button
													className="w-full sm:w-auto text-white uppercase px-4 py-2 rounded-lg border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
													onClick={() => searchFrm(false)}
												>
													Search
												</button>
											</div>
										</div>

										<div className="mt-5">
											{searching ? (
												<div className="text-center">Searching...</div>
											) : (
												<>
													{searchResults && searchResults.length > 0 && searchResults !== "blank" ? (
														<div className="overflow-x-auto">
															<table className="min-w-full divide-y divide-gray-300">
																<thead className="bg-gray-50">
																<tr>
																	<th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
																		Booking
																	</th>
																	<th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
																		Name
																	</th>
																	<th className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
																		Location
																	</th>

																	{(!process.env.REACT_APP_HIDE_CHAT_TABS ||
																		process.env.REACT_APP_HIDE_CHAT_TABS === "false") && (
																		<>
																			<th className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
																				Bed
																			</th>
																			<th className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
																				MDA TC
																			</th>
																			<th className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
																				Student No
																			</th>
																		</>
																	)}

																	{process.env.REACT_APP_HIDE_CHAT_TABS === "true" && (
																		<th className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
																			Unit/s
																		</th>
																	)}

																	<th className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
																		Status
																	</th>
																	<th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
																		Action
																	</th>
																</tr>
																</thead>
																<tbody className="divide-y divide-gray-200 bg-white">
																{searchResults.map((res) => (
																	<tr
																		key={`search_${res.id}`}
																		className={res.booking_merged_into !== null ? "bg-red-100" : ""}
																	>
																		<td className="whitespace-normal py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
																			{res.booking_merged_into !== null && (
																				<div className="font-bold">
																					<p>
																						Merged Into:
																						<br />#{res.user_merged_into}-{res.booking_merged_into}
																					</p>
																					<hr className="border-red-600" />
																				</div>
																			)}
																			{res.booking_reference} ({res.year}){" "}
																			{res.active_booking === res.id ? "(Active)" : ""}
																		</td>
																		<td className="whitespace-normal px-3 py-4 text-xs text-gray-500">
																			<p>
																				{res.name} {res.surname}
																			</p>
																			<p className="hidden sm:block">{res.email}</p>
																			<p className="hidden sm:block">{res.cell}</p>
																			<p className="hidden sm:block">{res.id_number}</p>
																		</td>
																		<td className="hidden sm:table-cell whitespace-normal px-3 py-4 text-xs text-gray-500">
																			{res.location}
																		</td>

																		{(!process.env.REACT_APP_HIDE_CHAT_TABS ||
																			process.env.REACT_APP_HIDE_CHAT_TABS === "false") && (
																			<>
																				<td className="hidden md:table-cell whitespace-normal px-3 py-4 text-xs text-gray-500">
																					{res.bed}
																				</td>
																				<td className="hidden md:table-cell whitespace-normal px-3 py-4 text-xs text-gray-500">
																					{res.mda_tenant_id}
																				</td>
																				<td className="hidden md:table-cell whitespace-normal px-3 py-4 text-xs text-gray-500">
																					{res.student_number}
																				</td>
																			</>
																		)}

																		{process.env.REACT_APP_HIDE_CHAT_TABS === "true" && (
																			<td className="hidden sm:table-cell whitespace-normal px-3 py-4 text-xs text-gray-500">
																				{res.aos_units}
																			</td>
																		)}

																		<td className="hidden sm:table-cell whitespace-normal px-3 py-4 text-xs text-gray-500">
																			{res.archived === 1 && <p>Archived</p>}
																			{res.closed === 1 && <p>Closed</p>}
																			{res.declined === 1 && <p>Declined</p>}
																			{!res.archived && !res.closed && !res.declined && <p>Open</p>}
																		</td>
																		<td className="py-4">
																			<Link
																				to={
																					searchRenewals === true
																						? `/booking/renewals?renewal_tag=${res.renewals_tag}&user_id=${res.user_id}&booking_id=${res.id}`
																						: searchCollections === false
																							? res.broker_info === 1
																								? `/booking/brokers?user_id=${res.user_id}&booking_id=${res.id}`
																								: res.booking_merged_into !== null
																									? res.declined
																										? `/booking/declined?user_id=${res.user_merged_into}&booking_id=${res.booking_merged_into}`
																										: res.tag
																											? `/booking/management?tag=${res.tag}&user_id=${res.user_merged_into}&booking_id=${res.booking_merged_into}`
																											: `/booking/${
																												res.archived == 1
																													? "archived"
																													: res.location?.toLowerCase().replace(/\s/g, "")
																											}?user_id=${res.user_merged_into}&booking_id=${
																												res.booking_merged_into
																											}`
																									: res.declined
																										? `/booking/declined?user_id=${res.user_id}&booking_id=${res.id}`
																										: res.tag
																											? `/booking/management?tag=${res.tag}&user_id=${res.user_id}&booking_id=${res.id}`
																											: `/booking/${
																												res.archived == 1
																													? "archived"
																													: res.location?.toLowerCase().replace(/\s/g, "")
																											}?user_id=${res.user_id}&booking_id=${res.id}`
																							: `/booking/collections?collection_tag=-1&user_id=${res.user_id}&booking_id=${res.id}`
																				}
																				onClick={() => {
																					dispatch(
																						getBooking(
																							res.booking_merged_into == null
																								? res.id
																								: res.booking_merged_into
																						)
																					);
																					dispatch(closeModal());
																				}}
																				className="block w-full sm:w-auto text-center text-white uppercase px-4 py-2 rounded-lg border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
																			>
																				Go
																			</Link>
																		</td>
																	</tr>
																))}
																</tbody>
															</table>
														</div>
													) : (
														<div className="text-center">No results found!</div>
													)}
												</>
											)}
										</div>
									</Dialog.Title>
								</div>
							</div>
						</Transition.Child>
					</div>
				</ErrorBoundary>
			</Dialog>
		</Transition.Root>
	);
}