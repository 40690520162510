import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsSelect from "../../components/Forms/SettingsSelect";

export default function ReportBedsByColour() {
    const [properties, setProperties] = useState([]);
    const [years, setYears] = useState([]);

    // Dropdown options for bed colour
    const bedColours = [
        { label: "Blocked Lease Signed (Black)", value: 1 },
        { label: "New Leads (Blue)", value: 3 },
        { label: "Renewals (Purple)", value: 4 },
        { label: "Moved In (Pink)", value: 5 },
    ];

    useEffect(() => {
        // Fetch properties
        axios
            .get(`${process.env.REACT_APP_API_URL}properties`)
            .then((response) => {
                const propertyOptions = [{ label: "All Properties", value: "0" }, ...response.data];
                setProperties(propertyOptions);
            })
            .catch(() => {
                alert("Failed to load properties");
            });

        // Fetch years from your existing get_years function
        axios
            .get(`${process.env.REACT_APP_API_URL}get_years`)
            .then((response) => {
                setYears(response.data); // Your API already returns the correct format
            })
            .catch(() => {
                alert("Failed to load years");
            });
    }, []);

    return (
        <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
            <h1>Export Beds by Colour</h1>

            <Formik
                initialValues={{
                    year_id: "",
                    property_id: "0",
                    bed_colour: "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    if (!values.year_id) {
                        alert("Please select a year");
                        return;
                    }

                    if (!values.bed_colour) {
                        alert("Please select a bed colour");
                        return;
                    }

                    await axios({
                        method: "post",
                        url: `${process.env.REACT_APP_API_URL}reports/export_beds_by_colour`,
                        responseType: "blob",
                        data: values,
                    })
                        .then((response) => {
                            let filename = "BedsByColour.xlsx";

                            if (values.bed_colour === 3) {
                                filename = "BedsByColour_NewLeads(Blue).xlsx";
                            } else if (values.bed_colour === 4) {
                                filename = "BedsByColour_Renewals(Purple).xlsx";
                            } else if (values.bed_colour === 5) {
                                filename = "BedsByColour_MovedIn(Pink).xlsx";
                            }

                            const href = URL.createObjectURL(response.data);
                            const link = document.createElement("a");
                            link.href = href;
                            link.setAttribute("download", filename);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        })
                        .catch((error) => console.log(error));

                    setSubmitting(false);
                }}
            >
                {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <div>
                            <div className="grid lg:grid-cols-12 md:lg:grid-cols-6 gap-12 mt-5 mb-5">
                                <SettingsSelect
                                    label="Select Year"
                                    name="year_id"
                                    onChange={setFieldValue}
                                    value={values.year_id}
                                    options={years} // Uses dynamically fetched years
                                />

                                <SettingsSelect label="Select Property" name="property_id" onChange={setFieldValue} value={values.property_id} options={properties} />

                                <SettingsSelect
                                    label="Select Bed Colour"
                                    name="bed_colour"
                                    onChange={setFieldValue}
                                    value={values.bed_colour}
                                    options={bedColours} // Uses hardcoded bed colours
                                />
                            </div>

                            <SubmitButton name="Submit" disabled={isSubmitting} isSubmitting={isSubmitting} text="Download Report" />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
