import React, {useState, useEffect, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import TimeSince from "../Helpers/TimeSince";
import {faUserPlus} from "@fortawesome/pro-regular-svg-icons";
import {useHistory, Link} from "react-router-dom";
import {toast} from "react-toastify";
import NotificationToast from "../Helpers/NotificationToast";
import CustomButton from "../Helpers/CustomButton";
import {getBooking} from "../../store/booking/bookingActions";
import {faFilePdf} from "@fortawesome/pro-solid-svg-icons";

export default function GridBookingInformation(props) {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [bookingFiles, setBookingFiles] = useState([]);
	const {current_booking} = useSelector((state) => state.booking);
	const {current_staff} = useSelector((state) => state.auth);
	const {settings} = useSelector((state) => state.settings);
	const [years, setYears] = useState(0);
	const [selectedYear, setSelectedYear] = useState(0);
	const [allowNewBooking, setAllowNewBooking] = useState(true);
	const filterProp = useRef();
	const [fileUrl, setFileUrl] = useState(null);

	const [historicBookings, setHistoricBookings] = useState([]);

	const sendHistoricBookings = (data) => {
		props.bookingGridCallback(data);
	};

	useEffect(async () => {
		axios({
			method: "get",
			url: `${process.env.REACT_APP_API_URL}get_years`,
		})
			.then((response) => {
				let dbYears = response.data;
				dbYears.sort((a, b) => a.value - b.value);
				setYears(dbYears);
			})
			.catch((error) => {
				alert("year load failed");
			});

		axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}booking_history`,
			data: {user_id: current_booking?.user?.id},
		})
			.then(async (response) => {
				setHistoricBookings(response.data);
				sendHistoricBookings(response.data);
				setBookingFiles(response.data[0].files);

				let bookingInfo = response.data;

				await Object.keys(bookingInfo).forEach((key) => {
					if (bookingInfo[key].year_id > settings.year_id) {
						setAllowNewBooking(false);
					}
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	async function booking_add(yearSelected) {
		if (yearSelected === 0) {
			toast(<NotificationToast title={"New Booking Year"} message="You need to select a year for the new booking"
									 type="error"/>);
		} else {
			setLoading(true);

			await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_URL}add-new-booking`,
				data: {user_id: current_booking?.user?.id, current_staff: current_staff, yearSelected: yearSelected},
			})
				.then((response) => {
					// setHistoricBookings(response.data);
					setLoading(false);
					dispatch(getBooking(response.data));
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}

	async function regenerateBookingSummary() {
		setLoading(true);
		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}regenerate_booking_summary`,
			data: {
				booking_id: current_booking.id,
				user_id: current_booking?.user?.id,
				staff_id: current_staff?.id,
			},
		})
			.then((response) => {
				toast(<NotificationToast title={"Booking Summary Regenerated"}
										 message={"The booking summary has been regenerated."}/>);
			})
			.catch((error) => {
				toast(<NotificationToast title="Something went wrong" message={error} type="error"/>);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	async function generateFileUrl() {
		setLoading(true);
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}get_booking_summary/${current_booking?.id}`);

			if (response.status === 200) {
				setFileUrl(response.data);
			} else {
				alert("No file found or invalid response");
			}
		} catch (error) {
			console.error("Error generating file URL:", error);
			alert("Something went wrong, we could not generate the file URL.");
		} finally {
			setLoading(false);
		}
	}

	return (
		<div>
			{/* {window.location.hostname === "localhost" ? */}
			{/* <> */}
			{/* {window.location.hostname === "localhost" && current_staff.id === 1 ? */}
			{allowNewBooking === true ? (
				<>
					{!current_staff.building_manager ? (
						<select
							ref={filterProp}
							className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary flex-none w-full"
							onChange={(v) => setSelectedYear(v.target.value)}
						>
							<option value="0" selected="0" disabled>
								Year
							</option>
							{years &&
								years.length > 0 &&
								years.map((u) => {
									if (u.value >= settings.year_id) {
										return (
											<option key={u.value} value={u.value}>
												{u.label}
											</option>
										);
									}
								})}
						</select>
					) : (
						""
					)}

					{!current_staff.building_manager ? (
						<CustomButton text={"Add Booking"} styling="w-full mt-2" icon={faUserPlus}
									  onClick={() => booking_add(selectedYear)} loading={loading}
									  loadingtext="Loading..."/>
					) : (
						""
					)}

					<br/>
				</>
			) : (
				""
			)}
			<p>List of Bookings:</p>
			{historicBookings &&
				historicBookings.length > 0 &&
				historicBookings.map((booking) => (
					<>
						{current_booking?.booking_reference === booking?.booking_reference ? (
							<>
								<div className="border-2 w-full rounded px-1 py-1 my-2 text-center border-green-500">
									<p>
										{booking.booking_reference} | {booking?.user?.active_booking == booking?.id ? "(Active)" : ""}
										<br/>
										{booking?.location} | {booking.year.year}
										{booking?.last_message_sent || booking?.last_message_rec_dt ? (
											<>
												<br/>
												<TimeSince
													time={Math.max(new Date(booking.last_message_sent || 0).getTime(), new Date(booking.last_message_rec_dt || 0).getTime())}/>
											</>
										) : null}
										{booking?.archived === 1 && (
											<span className="text-red-500">
                                                <br/>
                                                (Archived)
                                            </span>
										)}
									</p>
								</div>
							</>
						) : (
							<>
								{booking && (
									<Link
										to={
											booking?.archived === 1
												? "/booking/archived?user_id=" + booking.user.id + "&booking_id=" + booking.id
												: booking.tag > 0
													? "/booking/management?tag=" + booking.tag + "&user_id=" + booking.user.id + "&booking_id=" + booking.id
													: "/booking/" + booking?.location?.toLowerCase().replace(/\s/g, "") + "?user_id=" + booking?.user?.id + "&booking_id=" + booking?.id
										}
										onClick={() => dispatch(getBooking(0, booking?.user?.id, booking.id))}
									>
										<div
											className="border-2 w-full rounded px-1 py-1 my-2 text-center cursor-pointer bg-red-100">
											<p>
												{booking?.booking_reference} {booking?.user?.active_booking == booking?.id ? "(Active)" : ""}
												<br/>
												{booking?.location} | {booking.year.year}
												{booking?.last_message_sent || booking?.last_message_rec_dt ? (
													<>
														<br/>
														<TimeSince
															time={Math.max(new Date(booking.last_message_sent || 0).getTime(), new Date(booking.last_message_rec_dt || 0).getTime())}/>
													</>
												) : null}
												{booking?.archived === 1 && (
													<span className="text-red-500">
                                                        <br/>
                                                        (Archived)
                                                    </span>
												)}
											</p>
										</div>
									</Link>
								)}
							</>
						)}
					</>
				))}
			{/* </> */}
			{/* : "" */}
			{/* } */}
			{process.env.REACT_APP_COLOUR === "UC"
				&& (current_booking?.archived === 0 || current_booking?.archived === null)
				&& current_booking?.property_id > 0
				&& (
					<>
						<hr className="my-4"/>
						<p>Booking Confirmation:</p>
						<div className="mt-4 grid grid-cols-2 gap-4">
							<button
								onClick={() => regenerateBookingSummary()}
								className="bg-primary relative text-white uppercase px-4 py-2 rounded-lg text-sm focus:z-10 focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
							>
								{loading === true ? "LOADING..." : "REGENERATE"}
							</button>

							{fileUrl !== null && fileUrl !== "" ? (
								<a
									href={fileUrl}
									target="_blank"
									className="bg-primary relative text-white uppercase px-4 py-2 rounded-lg text-sm focus:z-10 focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
								>
									DOWNLOAD
								</a>
							) : (
								<CustomButton text="GENERATE LINK" icon={faFilePdf} onClick={() => generateFileUrl()}
											  loading={loading} loadingtext="Loading..."/>
							)}
						</div>
					</>
				)}
		</div>
	);
}
