import {useState, useEffect} from "react";
import {usePopper} from "react-popper";
import {useSelector} from "react-redux";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRoute} from "@fortawesome/pro-regular-svg-icons";
import {Popover} from "@headlessui/react";

export default function BookingwaBus({handleSubmit, platform, ...props}) {
	const {current_staff, staff} = useSelector((state) => state.auth);
	const {current_booking} = useSelector((state) => state.booking);
	const default_options = [
		{
			value: "bus_route_25",
			label: "UC Bus Route 2025",
			link: "bus_route_25",
			siteurl: "https://api.urbancircle.co.za/storage/whatsapp_templates/template_318/BUS%20ROUTES%20V3%202025.pdf",
		},
		{
			value: "bus_route_25_uj_stan",
			label: "UJ-Stan Bus Route 2025",
			link: "bus_route_25_uj_stan",
			siteurl: "https://api.urbancircle.co.za/uc_docs/UC Bus Route_2025_UJ Stanley Route.pdf",
		},
	];

	let [referenceElement, setReferenceElement] = useState();
	let [popperElement, setPopperElement] = useState();
	let [options, setOptions] = useState(default_options);
	let {styles, attributes} = usePopper(referenceElement, popperElement, {
		placement: "top",
	});

	let [filesList, setFilesList] = useState([]);

	useEffect(async () => {
		//
	}, []);

	return (
		<Popover>
			<Popover.Button
				ref={setReferenceElement}
				className="ml-4 relative inline-block text-white bg-primary px-2  py-1 rounded-xl hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
			>
				<FontAwesomeIcon className="h-4 w-4" aria-hidden="true" icon={faRoute}/>
			</Popover.Button>

			<Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper}
						   className="bg-white rounded-lg pb-4  z-20">
				<h1 className="bg-dark_background text-white p-2 rounded-t-lg">Routes</h1>
				<div className="flex flex-col items-start px-4 mt-2">
					{options?.map((o) => (
						<button key={"bpopt_" + o.value} type="button" className="text-gray-600 hover:text-primary"
								onClick={() => props.setFieldValue("message", o.siteurl)}>
							{o.label}
						</button>
					))}
				</div>
			</Popover.Panel>
		</Popover>
	);
}
