import React, {useEffect, useState, useCallback, useRef} from "react";
import {useParams, Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {SvgLoader, SvgProxy} from "react-svgmt";
import {openModal} from "../../store/modal/modalReducer";
import {useDropzone} from "react-dropzone";
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

// Custom hook for persistent map height
const usePersistentMapHeight = (initialHeight) => {
	const [height, setHeight] = useState(() => {
		const saved = localStorage.getItem('svgMapHeight');
		return saved || initialHeight;
	});

	useEffect(() => {
		localStorage.setItem('svgMapHeight', height);
	}, [height]);

	return [height, setHeight];
};

export default function Properties3Page() {
	const {property_id, floor_id} = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const {current_staff} = useSelector((state) => state.auth);
	const {settings, years} = useSelector((state) => state.settings);

	const [loading, setLoading] = useState(false);
	const [loading_1, setLoading_1] = useState(false);
	const [loading_3, setLoading_3] = useState(false);
	const [loading_4, setLoading_4] = useState(false);
	const [loading_5, setLoading_5] = useState(false);

	const [property, setProperty] = useState({});
	const [floor, setFloor] = useState({});
	const [units, setUnits] = useState([]);
	const [beds, setBeds] = useState([]);
	const [uploading, setUploading] = useState(false);

	const [booked, setBooked] = useState("");
	const [resident, setResident] = useState("");
	const [waitlisted, setWaitlisted] = useState("");
	const [movedIn, setMovedIn] = useState("");
	const [monthly, setMonthly] = useState("");
	const [perBed, setPerBed] = useState([]);

	const [mappedBeds, setMappedBeds] = useState(false);
	const [allowClick, setAllowClick] = useState(true);
	const [activeYear, setActiveYear] = useState(settings.year_id);
	const filterProp = useRef();
	const [svgMapHeight, setSvgMapHeight] = usePersistentMapHeight("80");
	const [unitGenders, setUnitGenders] = useState({});

	const fetchFloorUnitGenders = async (floorId) => {
		try {
			// Get all beds for the floor with their unit and gender info
			const response = await axios.get(`${process.env.REACT_APP_API_URL}get_floor_beds_gender/${floorId}`);

			// Process the beds to determine unit genders
			const unitGenderMap = {};

			response.data.forEach(bed => {
				if (!unitGenderMap[bed.unit_id]) {
					unitGenderMap[bed.unit_id] = {
						male: 0,
						female: 0
					};
				}

				if (bed.gender === 'male') {
					unitGenderMap[bed.unit_id].male += 1;
				} else if (bed.gender === 'female') {
					unitGenderMap[bed.unit_id].female += 1;
				}
			});

			// Determine final gender for each unit
			const finalUnitGenders = {};
			Object.entries(unitGenderMap).forEach(([unitId, counts]) => {
				if (counts.male > 0 && counts.female > 0) {
					finalUnitGenders[unitId] = 'mixed';
				} else if (counts.male > 0) {
					finalUnitGenders[unitId] = 'male';
				} else if (counts.female > 0) {
					finalUnitGenders[unitId] = 'female';
				} else {
					finalUnitGenders[unitId] = 'default';
				}
			});

			setUnitGenders(finalUnitGenders);
		} catch (error) {
			console.error("Failed to fetch floor unit genders:", error);
		}
	};

	const refreshBookingStates = async () => {
		if (process.env.REACT_APP_COLOUR === "UC") {
			try {
				const [bookedRes, residentRes, waitlistedRes, movedInRes] = await Promise.all([
					axios.get(`${process.env.REACT_APP_API_URL}get_floor_bed_bookings/${floor_id}/3/${activeYear}/0`),
					axios.get(`${process.env.REACT_APP_API_URL}get_floor_bed_bookings/${floor_id}/1/${activeYear}/0`),
					axios.get(`${process.env.REACT_APP_API_URL}get_floor_bed_bookings/${floor_id}/4/${activeYear}/0`),
					axios.get(`${process.env.REACT_APP_API_URL}get_floor_bed_bookings/${floor_id}/5/${activeYear}/0`)
				]);

				console.log(bookedRes.data);

				setBooked(bookedRes.data);
				setResident(residentRes.data);
				setWaitlisted(waitlistedRes.data);
				setMovedIn(movedInRes.data);
				setLoading_1(true);
				setLoading_3(true);
				setLoading_4(true);
				setLoading_5(true);
			} catch (error) {
				console.error("Failed to refresh booking states:", error);
			}
		} else if (process.env.REACT_APP_COLOUR === "QL") {
			try {
				const [residentRes, waitlistedRes, bookedRes, movedInRes, monthlyRes] = await Promise.all([
					axios.get(`${process.env.REACT_APP_API_URL}get_floor_unit_bookings/${floor_id}/1`),
					axios.get(`${process.env.REACT_APP_API_URL}get_floor_unit_bookings/${floor_id}/2`),
					axios.get(`${process.env.REACT_APP_API_URL}get_floor_unit_bookings/${floor_id}/3`),
					axios.get(`${process.env.REACT_APP_API_URL}get_floor_unit_bookings/${floor_id}/5`),
					axios.get(`${process.env.REACT_APP_API_URL}get_floor_unit_bookings/${floor_id}/6`)
				]);

				setResident(residentRes.data);
				setWaitlisted(waitlistedRes.data);
				setBooked(bookedRes.data);
				setMovedIn(movedInRes.data);
				setMonthly(monthlyRes.data);
			} catch (error) {
				console.error("Failed to refresh booking states:", error);
			}
		}
	};

	const updateBed = async (bedData) => {
		try {
			const response = await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_URL}update_bed`,
				data: bedData
			});

			setBeds(prevBeds => {
				return prevBeds.map(bed => {
					if (bed.id === bedData.id) {
						return {...bed, ...response.data};
					}
					return bed;
				});
			});

			await refreshBookingStates();
		} catch (error) {
			console.error("Failed to update bed:", error);
		}
	};

	const deleteMap = async () => {
		if (window.confirm("Are you sure?")) {
			try {
				await axios.delete(`${process.env.REACT_APP_API_URL}delete_map/${floor_id}`);
				setFloor({...floor, map_upload: ""});
			} catch (error) {
				console.error("Failed to delete map:", error);
			}
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);

			if (!loading && property_id > 0 && floor_id > 0) {
				try {
					const [propertyResponse, unitsResponse] = await Promise.all([
						axios.get(`${process.env.REACT_APP_API_URL}property_floor_info/${property_id}/${floor_id}/${activeYear}`),
						axios.get(`${process.env.REACT_APP_API_URL}get_floor_units_beds/${floor_id}`)
					]);

					setFloor(propertyResponse.data.floor);
					setProperty(propertyResponse.data.property);
					setPerBed(propertyResponse.data.beds_priced);
					setUnits(unitsResponse.data.units);
					setBeds(unitsResponse.data.beds);

					if (propertyResponse.data.floor.map_upload) {
						await Promise.all([
							refreshBookingStates(),
							fetchFloorUnitGenders(floor_id)
						]);
					}
				} catch (error) {
					console.error("Failed to fetch data:", error);
				} finally {
					setLoading(false);
				}
			}
		};

		fetchData();
	}, [mappedBeds, activeYear]);

	const onDrop = useCallback(async (acceptedFiles) => {
		setUploading(true);
		try {
			await Promise.all(acceptedFiles.map(uploadFile));
			await refreshBookingStates();
		} catch (error) {
			console.error("Upload failed:", error);
		} finally {
			setUploading(false);
		}
	}, []);

	const uploadFile = async (file) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("property_id", property_id);
		formData.append("floor_id", floor_id);

		await axios.post(`${process.env.REACT_APP_API_URL}upload_map`, formData);
	};

	const {getRootProps, getInputProps} = useDropzone({onDrop});

	return (
		<>
			<div className="h-screen w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
				<div className="flex justify-between">
					<h1>
						{property?.name} - {floor?.name}
					</h1>

					<select
						ref={filterProp}
						className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary flex-none"
						onChange={(e) => setActiveYear(e.target.value)}
					>
						<option value="0" disabled>Year</option>
						{years?.map((u) => (
							<option key={u.id} value={u.id} selected={activeYear === u.id}>
								{u.year}
							</option>
						))}
					</select>

					{current_staff?.id === 1 && (
						<div className="max-w-60">
							<label>Zoom Level (80 = 80% height)</label>
							<input
								type="text"
								name="svg_map_height"
								value={svgMapHeight}
								onChange={(e) => setSvgMapHeight(e.target.value)}
								className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
							/>
						</div>
					)}

					<div className="space-x-2">
						<Link
							className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
							to="/properties/step1"
						>
							Back to Properties
						</Link>
						<Link
							className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
							to={`/properties/step2/${property_id}`}
						>
							Back to Floors
						</Link>
					</div>
				</div>

				{floor.map_upload && (
					<div className="flex justify-center items-center space-x-12 mt-4">
						{/* Legend items */}
						<div className="flex space-x-2 justify-center items-center">
							<div className="w-5 h-5 rounded-full"
								 style={{backgroundColor: process.env.REACT_APP_COLOUR === "UC" ? "black" : "#c9c9c9"}}></div>
							<div className="font-bold">
								{process.env.REACT_APP_COLOUR === "UC"
									? (loading_1 === false ? "Loading..." : "Reserved / Blocked")
									: "Occupied"}
							</div>
						</div>

						<div className="flex space-x-2 justify-center items-center">
							<div className="w-5 h-5 rounded-full" style={{backgroundColor: "blue"}}></div>
							<div
								className="font-bold">{process.env.REACT_APP_COLOUR === "UC" ? (loading_3 === false ? "Loading..." : "New Leads") : "Last month of lease"}</div>
						</div>

						<div className="flex space-x-2 justify-center items-center">
							<div className="w-5 h-5 rounded-full" style={{backgroundColor: "purple"}}></div>
							<div
								className="font-bold">{process.env.REACT_APP_COLOUR === "UC" ? (loading_4 === false ? "Loading..." : "Renewal") : "2nd last month of lease"}</div>
						</div>

						<div className="flex space-x-2 justify-center items-center">
							<div className="w-5 h-5 rounded-full" style={{backgroundColor: "deeppink"}}></div>
							<div
								className="font-bold">{process.env.REACT_APP_COLOUR === "UC" ? (loading_5 === false ? "Loading..." : "Moved In") : "Overlapping Leases"}</div>
						</div>

						<div className="flex space-x-2 justify-center items-center">
							<div className="w-5 h-5 rounded-full" style={{backgroundColor: "green"}}></div>
							<div
								className="font-bold">{process.env.REACT_APP_COLOUR === "UC" ? "Mapped" : "Vacant"}</div>
						</div>

						{process.env.REACT_APP_COLOUR === "QL" ? (
							<div className="flex space-x-2 justify-center items-center">
								<div className="w-5 h-5 rounded-full" style={{backgroundColor: "#757575"}}></div>
								<div className="font-bold">Lease expired M2M</div>
							</div>
						) : (
							""
						)}

						{process.env.REACT_APP_COLOUR === "UC" ? (
							<div className="flex space-x-2 justify-center items-center">
								<div className="w-5 h-5 rounded-full" style={{backgroundColor: "#e5e7eb"}}></div>
								<div className="font-bold">Unmapped</div>
							</div>
						) : (
							""
						)}
					</div>
				)}

				<div className="map_svg" style={{height: `${svgMapHeight}vh`}}>
					{floor.map_upload ? (
						<>
							<SvgLoader
								path={`${process.env.REACT_APP_LARAVEL_URL}api/get_svg/${floor_id}?v=${Math.random()}`}
								style={{width: `${svgMapHeight}vw`, height: `${svgMapHeight}vh`}}
							>
								{process.env.REACT_APP_COLOUR === "QL" ?
									<SvgProxy selector=".mapped" fill="green" stroke="black"/> :
									<SvgProxy selector=".mapped" fill="green"/>}

								<SvgProxy
									selector=".room > g > rect.room_inner.highlighted, .room > g > path.room_inner.highlighted, .room
                                    > g > rect.room_container.highlighted, .room > g > path.room_container.highlighted"
									fill="#2ceaeb"
								/>
								<SvgProxy selector=".taken" fill="#ff0000"/>
								<SvgProxy selector=".resident_reserved"
										  fill={process.env.REACT_APP_COLOUR === "UC" ? "black" : "#c9c9c9"}/>
								<SvgProxy selector=".waitlist" fill="purple"/>

								<SvgProxy selector="rect:hover, path:hover" fill="orange"/>

								<SvgProxy
									selector="rect, path"
									onClick={(e) => {
										dispatch(
											openModal({
												modal_type: process.env.REACT_APP_COLOUR === "UC" ? "ModalPropBed" : "ModalPropUnit",
												modal_props: {
													property_id,
													floor_id,
													thisbed: e.currentTarget,
													setMappedBeds,
													mappedBeds,
													activeYear,
													updateBed,
												},
											})
										);
									}}
									onMouseEnter={(e) => {
										const bedId = e.currentTarget.getAttribute('bedid'); // Changed from data-bed-id to bedid
										if (bedId) {
											const bed = beds.find(b => b.id === parseInt(bedId));
											if (bed) {
												console.log(bed);
												e.currentTarget.setAttribute('data-tooltip-id', 'bed-tooltip');
												e.currentTarget.setAttribute(
													'data-tooltip-content',
													`Unit Type: ${bed.unit_type?.name}\nBed: ${bed.unit?.name}${bed.name}\nPrice: R${bed.price}\n${bed.unit_type_id}`
												);
											}
										}
									}}
								/>

								<SvgProxy selector=".blocked" fill="red"/>
								<SvgProxy selector=".blocked.yellow" fill="yellow"/>


								{booked != "" && (
									<SvgProxy
										selector={booked}
										fill="blue"
										onClick={(e) => {
											dispatch(
												openModal({
													modal_type: process.env.REACT_APP_COLOUR === "UC" ? "ModalPropBed" : "ModalPropUnit",
													modal_props: {
														property_id: property_id,
														floor_id: floor_id,
														thisbed: e.currentTarget,
														setMappedBeds: setMappedBeds,
														mappedBeds: mappedBeds,
														activeYear: activeYear,
													},
												})
											);
										}}
									/>
								)}

								{/* UC = waitlisted | QL = 2nd Last month of lease*/}
								{waitlisted != "" && (
									<SvgProxy
										selector={waitlisted}
										fill="purple"
										onClick={(e) => {
											dispatch(
												openModal({
													modal_type: process.env.REACT_APP_COLOUR === "UC" ? "ModalPropBed" : "ModalPropUnit",
													modal_props: {
														property_id: property_id,
														floor_id: floor_id,
														thisbed: e.currentTarget,
														setMappedBeds: setMappedBeds,
														mappedBeds: mappedBeds,
														activeYear: activeYear,
													},
												})
											);
										}}
									/>
								)}

								{/* UC = resident | QL = Occupied*/}
								{resident != "" && (
									<SvgProxy
										selector={resident}
										fill={process.env.REACT_APP_COLOUR === "UC" ? "black" : "#c9c9c9"}
										onClick={(e) => {
											dispatch(
												openModal({
													modal_type: process.env.REACT_APP_COLOUR === "UC" ? "ModalPropBed" : "ModalPropUnit",
													modal_props: {
														property_id: property_id,
														floor_id: floor_id,
														thisbed: e.currentTarget,
														setMappedBeds: setMappedBeds,
														mappedBeds: mappedBeds,
														activeYear: activeYear,
													},
												})
											);
										}}
									/>
								)}

								{movedIn !== "" && <SvgProxy selector={movedIn} fill="deeppink"/>}

								{monthly !== "" && <SvgProxy selector={monthly} fill="#757575"/>}

								<SvgProxy
									selector=".room"
									onElementSelected={(elements) => {
										Array.from(elements).forEach(element => {
											if (element.classList.contains('room')) {
												const unitId = element.getAttribute('unitid');
												const gender = unitGenders[unitId];

												// Define colors object for better maintenance
												const colors = {
													male: {
														fill: 'rgb(121,169,242)',
														stroke: '#4A75B8'
													},
													female: {
														fill: 'rgb(242,121,151)',
														stroke: '#B84A6E'
													},
													mixed: {
														fill: '#FFA500', // Orange for mixed gender
														stroke: '#CC8400' // Darker orange for stroke
													},
													default: {
														fill: '#E0E0E0',
														stroke: '#A0A0A0'
													}
												};

												// Get the appropriate colors based on gender
												const colorSet = colors[gender] || colors.default;

												// Apply the colors
												element.style.fill = colorSet.fill;
												element.style.stroke = colorSet.stroke;

												// Add a warning indicator for mixed units (optional)
												if (gender === 'mixed') {
													// You could add additional visual indicators here if needed
													element.style.strokeWidth = '2'; // Make the stroke thicker for mixed units
												}
											}
										});
									}}
								/>
							</SvgLoader>
							<Tooltip
								id="bed-tooltip"
								place="top"
								className="z-50"
								style={{
									backgroundColor: '#333',
									color: 'white',
									padding: '8px',
									borderRadius: '4px',
									fontSize: '14px',
									whiteSpace: 'pre-line' // This will respect the \n line breaks
								}}
							/>
						</>
					) : (
						<div className="bg-light_background mt-1 sm:mt-0 sm:col-span-2" {...getRootProps()}>
							<div
								className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-dark_background border-dashed rounded-md">
								<div className="space-y-1 text-center">
									{!uploading ? (
										<svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor"
											 fill="none" viewBox="0 0 48 48" aria-hidden="true">
											<path
												d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
												strokeWidth={2}
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									) : (
										<div>Uploading...</div>
									)}
									<div className="flex text-sm text-gray-600">
										<label
											className="relative cursor-pointer bg-light_background rounded-md font-medium text-primary hover:text-primary_hover focus-within:outline-none">
											{!uploading ? "Drop your files here" : "Files uploading"}
											<input {...getInputProps()} />
										</label>
									</div>
									{!uploading && <p className="text-xs text-gray-500">up to 10MB per file</p>}
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="grid grid-cols-2 mt-4">
					<div>
						{perBed?.length > 0 && (
							<>
								<p className="text-xl font-bold">Priced per Bed:</p>
								<ul className="mt-2">
									{perBed.map((bed) => (
										<li key={`${bed.unitName}-${bed.bedName}`}>
											{bed.unitName} - {bed.bedName}
										</li>
									))}
								</ul>
							</>
						)}
					</div>

					<div>
						<div>Beds Count = {beds.length}</div>
						<div>Blocked Red Count = {beds.filter((b) => b.bed_status === 1)?.length}</div>
						<div>Blocked Yellow Count = {beds.filter((b) => b.bed_status === 2)?.length}</div>
						<div>Pink Count = {movedIn?.split(",")?.length}</div>
						<div>Black Count = {resident?.split(",")?.length - movedIn?.split(",")?.length}</div>
					</div>
				</div>
			</div>
		</>
	);
}