import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsSelect from "../../components/Forms/SettingsSelect";

const LeaseStatusBadge = ({ leaseStatus }) => {
    const colors = {
        black: "bg-gray-800 text-white",
        pink: "bg-pink-400",
        none: "bg-transparent"
    };

    const displayText = {
        black: "Black",
        pink: "Pink",
        none: ""
    };

    if (leaseStatus === 'none') return null;

    return (
        <span className={`px-2 py-1 rounded-full text-sm ${colors[leaseStatus]}`}>
            {displayText[leaseStatus]}
        </span>
    );
};

const StatusBadge = ({ status }) => {
    const colors = {
        unallocated: "bg-gray-200",
        male: "bg-blue-200",
        female: "bg-pink-200",
        mixed: "bg-purple-200",
        pending: "bg-yellow-200",
        red_block: "bg-red-300",
        yellow_block: "bg-yellow-200",
    };

    const displayText = status
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

    return (
        <span className={`px-2 py-1 rounded-full text-sm ${colors[status]}`}>
            {displayText}
        </span>
    );
};

export default function BedAllocationReport() {
    const [loading, setLoading] = useState(false);
    const [properties, setProperties] = useState([]);
    const [reportData, setReportData] = useState(null);
    const [expanded, setExpanded] = useState({});
    const { current_staff } = useSelector((state) => state.auth);

    const years = [
        { label: "2023", value: 1 },
        { label: "2024", value: 2 },
        { label: "2025", value: 3 },
    ];

    useEffect(() => {
        loadProperties();
    }, []);

    const loadProperties = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}properties`);

            // Add "All Properties" option
            const allProperties = [{ id: 0, label: "All Properties", value: 0 }];

            // Map the properties correctly
            response.data.forEach((prop) => {
                allProperties.push({
                    id: prop.value, // Use `value` as the ID
                    label: prop.label, // Use `label` as the property name
                    value: prop.value, // Use `value` as the dropdown value
                    name: prop.label, // Use `label` as the name
                });
            });

            console.log("Processed properties:", allProperties);
            setProperties(allProperties);
        } catch (error) {
            console.error("Error loading properties:", error);
        }
    };

    const toggleExpand = (key) => {
        setExpanded((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const handleGenerateReport = async (values, { setSubmitting }) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}reports/bed-allocation`, {
                params: {
                    property_id: values.property_id === 0 ? null : values.property_id,
                    year_id: values.year_id,
                },
            });
            setReportData(response.data);
        } catch (error) {
            console.error("Error loading report:", error);
        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    };

    return (
        <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
            <h1 className="text-2xl font-semibold text-gray-900 mb-6">Bed Allocation Report</h1>

            <Formik
                initialValues={{
                    year_id: 3,
                    property_id: 0,
                }}
                onSubmit={handleGenerateReport}
            >
                {({ values, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="grid lg:grid-cols-12 md:lg:grid-cols-6 gap-12 mt-5 mb-5">
                            <SettingsSelect label="Year" name="year_id" onChange={setFieldValue} value={values.year_id} options={years} />
                            <SettingsSelect label="Select Building (Optional)" name="property_id" onChange={setFieldValue} value={values.property_id} options={properties} />
                        </div>

                        <SubmitButton name="Submit" disabled={!isValid || isSubmitting} isSubmitting={isSubmitting} text="Generate Report" />

                        {loading && (
                            <div className="mt-4 flex items-center justify-center">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                                <span className="ml-2">Generating report...</span>
                            </div>
                        )}

                        {reportData && !loading && (
                            <div className="mt-6">
                                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                    <div className="px-4 py-5 sm:p-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Overall Summary</h3>
                                        <div className="mt-4 grid grid-cols-5 gap-4">
                                            <div className="bg-gray-50 px-4 py-2 rounded-lg">
                                                <dt className="text-sm font-medium text-gray-500">Total Beds</dt>
                                                <dd className="mt-1 text-2xl font-semibold text-gray-900">{reportData.totals.total}</dd>
                                            </div>
                                            {Object.entries(reportData.totals).map(
                                                ([key, value]) =>
                                                    key !== "total" && (
                                                        <div key={key} className="bg-gray-50 px-4 py-2 rounded-lg">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                {key
                                                                    .split("_")
                                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(" ")}
                                                            </dt>
                                                            <dd className="mt-1 text-2xl font-semibold text-gray-900">{value}</dd>
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 space-y-4">
                                    {reportData.properties.map((property) => (
                                        <div key={property.id} className="bg-white shadow sm:rounded-lg">
                                            <div className="px-4 py-5 sm:p-6 cursor-pointer hover:bg-gray-50" onClick={() => toggleExpand(`property-${property.id}`)}>
                                                <div className="flex items-center justify-between">
                                                    <h3 className="text-lg leading-6 font-medium text-gray-900">{property.name}</h3>
                                                    <span className="transform transition-transform duration-200">{expanded[`property-${property.id}`] ? "▼" : "▶"}</span>
                                                </div>
                                                <div className="mt-2 grid grid-cols-5 gap-4">
                                                    <div className="text-sm">
                                                        <span className="text-gray-500">Total Beds: </span>
                                                        <span className="font-medium">{property.summary.total}</span>
                                                    </div>
                                                    {Object.entries(property.summary).map(
                                                        ([key, value]) =>
                                                            key !== "total" && (
                                                                <div key={key} className="text-sm">
                                                                    <span className="text-gray-500">
                                                                        {key
                                                                            .split("_")
                                                                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                            .join(" ")}
                                                                        :{" "}
                                                                    </span>
                                                                    <span className="font-medium">{value}</span>
                                                                </div>
                                                            )
                                                    )}
                                                </div>
                                            </div>

                                            {expanded[`property-${property.id}`] && (
                                                <div className="border-t border-gray-200">
                                                    {property.floors.map((floor) => {
                                                        // Calculate floor totals
                                                        const floorBedCount = floor.units.reduce((acc, unit) =>
                                                            acc + unit.beds.length, 0
                                                        );

                                                        // Calculate unit type counts for the floor
                                                        const unitTypeCounts = floor.units.reduce((acc, unit) => {
                                                            unit.beds.forEach(bed => {
                                                                acc[bed.type] = (acc[bed.type] || 0) + 1;
                                                            });
                                                            return acc;
                                                        }, {});

                                                        return (
                                                            <div key={floor.id} className="px-4 py-5 sm:p-6 border-b">
                                                                <div className="flex justify-between items-center">
                                                                    <h4 className="text-md font-medium text-gray-900">
                                                                        {floor.name} ({floorBedCount} beds) <a className="text-blue-500 underline" href={`/properties/step2/${property.id}`} target="_blank">View Property</a> | <a className="text-blue-500 underline" href={`/properties/step3/${property.id}/${floor.id}`} target="_blank">View Floor</a>
                                                                    </h4>
                                                                    <div className="flex gap-4">
                                                                        {Object.entries(unitTypeCounts).map(([type, count]) => (
                                                                            <span key={type} className="text-sm text-gray-600">
                                                                                {type}: {count}
                                                                            </span>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <div className="mt-4 space-y-4">
                                                                    {floor.units.map((unit) => (
                                                                        <div key={unit.id} className="pl-4">
                                                                            <h5 className="text-sm font-medium text-gray-900">{unit.name}</h5>
                                                                            <div className="mt-2 grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
                                                                                {unit.beds.map((bed) => (
                                                                                    <div key={bed.id} className="flex items-center space-x-2">
                                                                                        <span className="text-sm text-gray-500">
                                                                                            {bed.name} ({bed.type})
                                                                                        </span>
                                                                                        <StatusBadge status={bed.status} />
                                                                                        <LeaseStatusBadge
                                                                                            leaseStatus={
                                                                                                bed.lease_signed ? 'black' :
                                                                                                    bed.inspection_date ? 'pink' :
                                                                                                        'none'
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
}
