import React, {Fragment, useState, useEffect} from "react";
import {closeModal} from "../../store/modal/modalReducer";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {Dialog, Transition} from "@headlessui/react";
import {Formik, Form} from "formik";
import ErrorBoundary from "../../containers/ErrorBoundary";
import SubmitButton from "../Forms/SubmitButton";
import {toast} from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-regular-svg-icons";
import TimeSince from "../Helpers/TimeSince";
import moment, {now} from "moment/moment";

export default function ModalBulkArchive(props) {
	const dispatch = useDispatch();
	const [open] = useState(true);
	const [listLoaded, setListLoaded] = useState(false);
	const [sendList, setSendList] = useState([]);
	const {properties} = useSelector((state) => state.properties);
	const [selClosed, setSelClosed] = useState("");
	const [selWifi, setSelWifi] = useState("");
	const [selYear, setSelYear] = useState(0);
	const [selBudget, setSelBudget] = useState("");
	const [selMessageFilter, setSelMessageFilter] = useState("");
	const [selGender, setSelGender] = useState("");
	const [selHasBed, setSelHasBed] = useState("");
	const [closeType, setCloseType] = useState([
		{label: "Open", value: 0},
		{label: "Closed", value: 1},
	]);
	const years = [
		// { label: "2023", value: 1 },
		{label: "2024", value: 2},
		{label: "2025", value: 3},
	];

	const [wifiType, setWifiType] = useState([
		{label: "WiFi OFF", value: 0},
		{label: "WiFi ON", value: 1},
	]);
	const [studentTypes, setStudentTypes] = useState([
		{label: "NSFAS UJ", value: 4},
		{label: "NSFAS WITS", value: 5},
		// { label: "CJC", value: 6 },
		{label: "NSFAS TVET", value: 1},
		{label: "Bursary", value: 2},
		{label: "Private", value: 3},
	]);
	const [genderTypes] = useState([
		{label: "Male", value: 1},
		{label: "Female", value: 2},
	]);
	const [selStudentType, setSelStudentType] = useState("");
	const [actionType, setActionType] = useState([]);
	const [selOlderThan, setSelOlderThan] = useState(0);
	const [studyYear, setStudyYear] = useState(0);
	const [action, setAction] = useState("");
	const [locations, setLocations] = useState([]);
	const {tags} = useSelector((state) => state.tags);
	const [chosenLocation, setChosenLocation] = useState([]);
	const [chosenTag, setChosenTag] = useState([]);
	const [selProperty, setSelProperty] = useState("");
	const [selBookingSummary, setSelBookingSummary] = useState(0);
	const [selUnitType, setSelUnitType] = useState(0);

	const [locationChecked, setLocationChecked] = useState(false);
	const [messageFilterType, setMessageFilterType] = useState(0);

	const {current_staff} = useSelector((state) => state.auth);
	const {renewals} = useSelector((state) => state.renewals);
	const [lastMsgFrom, setSelLastMsgFrom] = useState(0);
	const [lookups, setLookups] = useState([]);

	useEffect(() => {
		if (locationChecked === false) {
			setLocationChecked(true);
			if (props.type === "collections") {
				setActionType([
					{label: "Turn WiFi ON", value: 4},
					{label: "Turn WiFi OFF", value: 5},
				]);
			} else if (props.type !== "collections" && props.type !== "renewals") {
				setActionType([
					{label: "Archive", value: 1},
					{label: "Close", value: 2},
					{label: "Move", value: 3},
					{label: "Set Active", value: 7},
					{label: "MDA Create Tenant + Lease", value: 6},
				]);
			} else {
				setActionType([
					{label: "Close", value: 2},
					{label: "Move", value: 3},
					{label: "Move from Renewal", value: 8},
					{label: "Set Active", value: 7},
				]);
			}
		}

		if (listLoaded === false) {
			let sendListNew = props.bookings.filter(function (sl) {
				// let sendListNew = props.allBookings.filter(function (sl) {
				if (!sl.checked) {
					sl.checked = true;
				}

				if (
					selProperty === "" ||
					parseInt(selProperty) === parseInt(sl.i) ||
					(parseInt(selProperty) === 1000 && sl.i === null)
				) {
					if (
						selStudentType === "" ||
						parseInt(selStudentType) === parseInt(sl.v)
					) {
						if (
							selClosed === "" ||
							(selClosed == 1 && sl.g === 1) ||
							(selClosed == 0 && sl.g !== 1)
						) {
							if (studyYear == 0 || studyYear == sl.am) {
								if (
									selWifi === "" ||
									(selWifi == 1 && sl.ac === 1) ||
									(selWifi == 0 && sl.ac === 0)
								) {
									if (
										selHasBed === "" ||
										(selHasBed === "1" && sl.ar > 0) ||
										(selHasBed === "2" && (!sl.ar || sl.ar === 0))
									) {
										if (
											parseInt(selBookingSummary) === 0 ||
											(parseInt(selBookingSummary) === 1 && sl.ap === "yes") ||
											(parseInt(selBookingSummary) === 2 && sl.ap === "no")
										) {
											if (selGender === "" || sl.x === parseInt(selGender)) {
												if (selBudget === "" || parseInt(selBudget) === sl.aq) {
													if (
														parseInt(selUnitType) === 0 ||
														(parseInt(selUnitType) === 1 && sl.u > 0) ||
														(parseInt(selUnitType) === 2 &&
															(sl.u === 0 || sl.u === null))
													) {
														if (selYear == 0 || selYear == sl.af) {
															if (
																selOlderThan === 0 ||
																selOlderThan == "" ||
																moment(
																	moment()
																		.subtract(parseFloat(selOlderThan), "days")
																		.format("Y-MM-DD")
																).isSameOrBefore(
																	moment(
																		moment(
																			sl[lastMsgFrom == 1 ? "n" : "nn"]
																				? sl[lastMsgFrom == 1 ? "n" : "nn"]
																				: now()
																		).format("Y-MM-DD")
																	)
																) === false
															) {
																// Add message filter check
																if (selMessageFilter !== "") {
																	const lastMessage = getLastMessage(sl);
																	return lastMessage
																		.toLowerCase()
																		.includes(selMessageFilter.toLowerCase());
																}
																return true;
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				} else {
					return false;
				}
			});

			setSendList(sendListNew);
			getFolders();
			setListLoaded(true);
		}
	}, [listLoaded]);

	const getLastMessage = (booking) => {
		const nTime = new Date(booking.n).getTime() || 0;
		const nnTime = new Date(booking.nn).getTime() || 0;

		// If nn timestamp is more recent, show oo message
		// Otherwise show o message
		if (nnTime > nTime) {
			return booking.oo
				? booking.oo.replace(/<\/?[^>]+(>|$)/g, "")
				: booking.o
					? booking.o.replace(/<\/?[^>]+(>|$)/g, "")
					: "No Message";
		} else {
			return booking.o
				? booking.o.replace(/<\/?[^>]+(>|$)/g, "")
				: booking.oo
					? booking.oo.replace(/<\/?[^>]+(>|$)/g, "")
					: "No Message";
		}
	};

	useEffect(async () => {
		axios({
			method: "get",
			url: `${process.env.REACT_APP_API_URL}uc_lookups`,
		})
			.then((response) => {
				setLookups(response.data);
			})
			.catch((error) => {
				alert("lookups failed");
			});
	}, []);

	async function getFolders() {
		await axios({
			method: "get",
			url: `${process.env.REACT_APP_API_URL}folders`,
		}).then((response) => {
			let allFolders = response.data.folders;
			let locationList = [];
			Object.keys(allFolders).forEach((key) => {
				if (
					allFolders[key].name !== "Declined" &&
					allFolders[key].name !== "Cancelled" &&
					allFolders[key].name !== "Archived" &&
					allFolders[key].name !== "All"
				) {
					if (allFolders[key].name == "All Residents") {
						allFolders[key].name = "Residents";
					}
					locationList.push({
						value: allFolders[key].name,
						label: allFolders[key].name,
					});
				}
			});

			setLocations(locationList);
		});
	}

	function changeList(e, res) {
		let sendListNew = sendList.map((sl) => {
			if (!sl.checked) {
				sl.checked = false;
			}

			if (sl.a === res.a) {
				sl.checked = e.target.checked;
			}

			return sl;
		});

		setSendList(sendListNew);
	}

	function changeListAll(checked) {
		let sendListNew = sendList.map((sl) => {
			sl.checked = checked;

			return sl;
		});

		setSendList(sendListNew);
	}

	function setClosed(e) {
		setSelClosed(e.target.value);
		setListLoaded(false);
	}

	function setWifi(e) {
		setSelWifi(e.target.value);
		setListLoaded(false);
	}

	function setYear(e) {
		setSelYear(e.target.value);
		setListLoaded(false);
	}

	function setBookingSummary(e) {
		setSelBookingSummary(e);
		setListLoaded(false);
	}

	function setBudget(e) {
		setSelBudget(e.target.value);
		setListLoaded(false);
	}

	function setUnitType(e) {
		setSelUnitType(e);
		setListLoaded(false);
	}

	function setLastMsgFrom(e) {
		setSelLastMsgFrom(e.target.value);
		setListLoaded(false);
	}

	async function setProp(e) {
		setSelProperty(e.target.value);
		setListLoaded(false);
	}

	function setFunding(e) {
		setSelStudentType(e.target.value);
		setListLoaded(false);
	}

	function setMessageFilter(e) {
		setSelMessageFilter(e);
		setListLoaded(false);
	}

	function setGender(e) {
		setSelGender(e.target.value);
		setListLoaded(false);
	}

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-50 inset-0 overflow-y-auto"
				open={open}
				onClose={() => dispatch(closeModal())}
			>
				<div
					className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
            &#8203;
          </span>

					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div
							className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
							<div className="flex">
								<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
									<Dialog.Title
										as="h1"
										className="text-lg font-medium text-gray-900 capitalize"
									>
										Bulk Action - {props.booking_list_name}
									</Dialog.Title>
								</div>

								<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-right grow">
									<button
										type="button"
										className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
										onClick={() => dispatch(closeModal())}
									>
										<span className="sr-only">Close</span>
										<FontAwesomeIcon
											className="h-6 w-6"
											aria-hidden="true"
											icon={faTimes}
										/>
									</button>
								</div>
							</div>
							<div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
								<Dialog.Panel>
									<ErrorBoundary>
										<Formik
											initialValues={{
												from: props.current_staff?.id,
												to: "",
												files: [],
												template: 0,
											}}
											validateOnChange={false}
											validateOnBlur={false}
											onSubmit={async (
												values,
												{setSubmitting, setErrors, resetForm}
											) => {
												//Filter out just ticked items
												let sendListNew = sendList.filter(function (sl) {
													return sl.checked === true;
												});

												if (action === "") {
													alert("Please choose an Action!");
												} else {
													if (sendListNew.length === 0) {
														alert("Please choose at least 1 person!");
													} else if (action === 3 && chosenLocation === "") {
														alert("Please choose a location to move!");
													} else {
														setSubmitting(true);

														async function processArray(array) {
															let actionUrl = "";
															let actionMethod = "post";
															let bulkData = [];

															if (action === "1") {
																actionUrl = "bulk-archive";
																bulkData = array.map((item) => ({
																	archive: true,
																	current_staff_id: current_staff.id,
																	type: props.type,
																	id: item.a,
																}));
															} else if (action === "2") {
																actionUrl = "bulk-toggle";
																bulkData = array.map((item) => ({
																	closed: 1,
																	current_staff_id: current_staff.id,
																	type: props.type,
																	id: item.a,
																}));
															} else if (action === "3" || action === "8") {
																actionUrl = "bulk-assign";
																bulkData = array.map((item) => ({
																	location:
																		chosenLocation === "General"
																			? "General"
																			: chosenLocation,
																	tag: chosenTag === "" ? 0 : chosenTag,
																	current_staff_id: current_staff.id,
																	type:
																		action === 8 ? "fromrenewal" : props.type,
																	year_id: selYear,
																	id: item.a,
																}));
															} else if (action === "4" || action === "5") {
																actionUrl = "bulk-wifi-status-update";
																bulkData = array.map((item) => ({
																	user_id: item.f,
																	property_id: item.i,
																	location: chosenLocation,
																	tag: chosenTag === "" ? 0 : chosenTag,
																	current_staff: current_staff,
																	type: props.type,
																}));
															} else if (action === "6") {
																actionUrl = "mda/bulk_link_mda";
																bulkData = array.map((item) => ({
																	user_id: item.f,
																	booking_id: item.a,
																	property_id: item.i,
																	current_staff: current_staff,
																	type: props.type,
																}));
															}

															try {
																const response = await axios({
																	method: actionMethod,
																	url:
																		process.env.REACT_APP_API_URL + actionUrl,
																	data: {items: bulkData},
																});

																const {message} = response.data;

																toast(
																	<NotificationToast
																		title="Actioned Successfully"
																		message={
																			message ||
																			"All records have been updated successfully."
																		}
																	/>
																);
															} catch (error) {
																const errorMessage =
																	error.response?.data?.message ||
																	error.message ||
																	"An error occurred";

																toast(
																	<NotificationToast
																		title="Something went wrong"
																		message={errorMessage}
																		type="error"
																	/>
																);
															}

															return true;
														}

														if (action === "7") {
															await axios({
																method: "post",
																url:
																	process.env.REACT_APP_API_URL +
																	"bulk_set_active",
																data: sendListNew,
															})
																.then((response) => {
																	toast(
																		<NotificationToast
																			title={"Actioned Succesfully"}
																			message={
																				"The booking has been updated succesfully."
																			}
																		/>
																	);
																})
																.catch((error) => {
																	toast(
																		<NotificationToast
																			title="Something went wrong"
																			message={error}
																			type="error"
																		/>
																	);
																});
														} else {
															await processArray(sendListNew);
														}

														setSubmitting(false);

														resetForm();
													}
												}
											}}
										>
											{({
												  values,
												  errors,
												  setFieldValue,
												  handleSubmit,
												  isValid,
												  isSubmitting,
												  dirty,
											  }) => (
												<Form
													onSubmit={handleSubmit}
													className="rounded-3xl w-full mt-3"
												>
													{/* For some reason this overflow isn't working, I want to make the table scrollable */}
													<div className="max-h-96 overflow-auto">
														<div
															className="flex justify-start p-2 bg-dark_background flex-wrap">
															<select
																name="action_type"
																onChange={(e) => setAction(e.target.value)}
																className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
															>
																<option value="">Action</option>

																{actionType &&
																	actionType.length > 0 &&
																	actionType.map((p) => (
																		<option key={p.value} value={p.value}>
																			{p.label}
																		</option>
																	))}
															</select>

															{action && (action === "3" || action === "8") ? (
																<>
																	<select
																		name="action_type"
																		onChange={(e) =>
																			setChosenLocation(e.target.value)
																		}
																		className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
																	>
																		<option value="">Location</option>

																		{props.type != "renewals" ||
																		action === "8" ? (
																			<>
																				{locations &&
																					locations.length > 0 &&
																					locations.map((p) => (
																						<option
																							key={p.value}
																							value={p.value}
																						>
																							{p.label}
																						</option>
																					))}
																			</>
																		) : (
																			<>
																				{renewals &&
																					renewals.length > 0 &&
																					renewals.map((p) => (
																						<option key={p.id}
																								value={p.name}>
																							{p.name}
																						</option>
																					))}
																			</>
																		)}
																	</select>
																</>
															) : (
																""
															)}

															{action &&
															(action === "3" || action === "8") &&
															chosenLocation &&
															(chosenLocation == "Residents" ||
																chosenLocation == "General") ? (
																<>
																	<select
																		name="action_type"
																		onChange={(e) =>
																			setChosenTag(e.target.value)
																		}
																		className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
																	>
																		<option value="">No Tag</option>

																		{tags &&
																			tags.length > 0 &&
																			tags
																				.filter(
																					(tag) =>
																						tag.parent_folder ===
																						chosenLocation ||
																						(tag.parent_folder ===
																							"Historical" &&
																							chosenLocation ==
																							"Management 2023")
																				)
																				.map((p) => (
																					<option key={p.id} value={p.id}>
																						{p.name}
																					</option>
																				))}
																	</select>
																</>
															) : (
																""
															)}

															<select
																name="property_id"
																onChange={(e) => setProp(e)}
																className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
															>
																<option value="">All Buildings</option>
																<option value="1000">No Building</option>

																{properties &&
																	properties.length > 0 &&
																	properties.map((p) => (
																		<option key={p.value} value={p.value}>
																			{p.label}
																		</option>
																	))}
															</select>

															{process.env.REACT_APP_COLOUR == "UC" && (
																<select
																	name="funding_type"
																	onChange={(e) => setFunding(e)}
																	className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
																>
																	<option value="">All Funding Types</option>

																	{studentTypes &&
																		studentTypes.length > 0 &&
																		studentTypes.map((p) => (
																			<option key={p.value} value={p.value}>
																				{p.label}
																			</option>
																		))}
																</select>
															)}

															<select
																name="chat_type"
																onChange={(e) => setClosed(e)}
																className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
															>
																<option value="">Open and Closed</option>

																{closeType &&
																	closeType.length > 0 &&
																	closeType.map((p) => (
																		<option key={p.value} value={p.value}>
																			{p.label}
																		</option>
																	))}
															</select>

															<select
																name="year_id"
																onChange={(e) => setYear(e)}
																className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
															>
																<option value="0">All years</option>

																{years &&
																	years.length > 0 &&
																	years.map((p) => (
																		<option key={p.value} value={p.value}>
																			{p.label}
																		</option>
																	))}
															</select>

															<select
																name="last_msg_from"
																onChange={(e) => setLastMsgFrom(e)}
																className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
															>
																<option value="0">
																	Last Message From Tenant
																</option>
																<option value="1">Last Message</option>
															</select>

															{/* <select
																name="message_filter_type"
																onChange={(e) => setMessageFilterType(e)}
																className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
															  >
																<option value="0">All</option>
																<option value="1">Last Student Message</option>
																<option value="2">Last Romi Message</option>
															  </select> */}

															{props.type === "collections" ? (
																<>
																	<select
																		name="chat_type"
																		onChange={(e) => setWifi(e)}
																		className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
																	>
																		<option value="">WiFi ON and OFF</option>

																		{wifiType &&
																			wifiType.length > 0 &&
																			wifiType.map((p) => (
																				<option key={p.value} value={p.value}>
																					{p.label}
																				</option>
																			))}
																	</select>
																</>
															) : (
																""
															)}

															<input
																type="number"
																placeholder="Older than X days"
																className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
																onChange={(value) => {
																	setSelOlderThan(value.target.value);
																	setListLoaded(false);
																}}
															/>

															<select
																name="study_year_id"
																className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
																onChange={(value) => {
																	setStudyYear(value.target.value);
																	setListLoaded(false);
																}}
															>
																<option value="0">All Study Years</option>

																{lookups[8] &&
																	lookups[8].length > 0 &&
																	lookups[8].map((p) => (
																		<option key={p.value} value={p.value}>
																			{p.label}
																		</option>
																	))}
															</select>

															<select
																name="unit_type"
																onChange={(e) => setUnitType(e.target.value)}
																className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
															>
																<option value="0">Ignore Unit Type</option>
																<option value="1">Has Unit Type</option>
																<option value="2">No Unit Type</option>
															</select>

															<select
																name="booking_summary"
																onChange={(e) =>
																	setBookingSummary(e.target.value)
																}
																className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
															>
																<option value="0">
																	Ignore Booking Summary
																</option>
																<option value="1">Has Booking Summary</option>
																<option value="2">No Booking Summary</option>
															</select>

															<select
																name="budget_id"
																onChange={(e) => setBudget(e)}
																className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
															>
																<option value="">All Budgets</option>

																{props.lookups[15] &&
																	props.lookups[15].length > 0 &&
																	props.lookups[15].map((p) => (
																		<option key={p.value} value={p.value}>
																			{p.label}
																		</option>
																	))}
															</select>

															<select
																name="gender_type"
																onChange={(e) => setGender(e)}
																className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
															>
																<option value="">All Genders</option>
																{genderTypes.map((p) => (
																	<option key={p.value} value={p.value}>
																		{p.label}
																	</option>
																))}
															</select>

															<select
																name="has_bed"
																onChange={(e) => {
																	setSelHasBed(e.target.value);
																	setListLoaded(false);
																}}
																className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
															>
																<option value="">All Bed Status</option>
																<option value="1">Has Bed</option>
																<option value="2">No Bed</option>
															</select>

															<input
																type="text"
																placeholder="Filter by message content"
																className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
																onChange={(e) => setMessageFilter(e.target.value)}
																value={selMessageFilter}
															/>
														</div>

														<table className="min-w-full divide-y divide-gray-300">
															<thead className="bg-gray-50">
															<tr>
																<th
																	colSpan="5"
																	scope="col"
																	className="py-3.5 pl-4 pr-3 text-center font-bold text-gray-900 sm:pl-6"
																>
																	Audience -{" "}
																	{sendList &&
																		sendList.filter(
																			(sl) => sl.checked === true
																		).length}{" "}
																	Selected
																</th>
																<th
																	colSpan="2"
																	scope="col"
																	className="py-3.5 pl-4 pr-3 text-center font-bold text-gray-900 sm:pl-6"
																>
																	Select All
																	<input
																		type="checkbox"
																		className="ml-2"
																		onChange={(e) =>
																			changeListAll(e.target.checked)
																		}
																	/>
																</th>
															</tr>
															<tr>
																<th
																	scope="col"
																	className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
																>
																	Building
																</th>
																<th
																	scope="col"
																	className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
																>
																	Booking - Year
																</th>
																<th
																	scope="col"
																	className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
																>
																	Location
																</th>
																<th
																	scope="col"
																	className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
																>
																	Name | Last Romi Msg | Last Msg
																</th>
																<th
																	scope="col"
																	className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
																>
																	Open
																</th>
																{props.type === "collections" ? (
																	<>
																		<th
																			scope="col"
																			className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
																		>
																			WiFi Status
																		</th>
																	</>
																) : (
																	""
																)}
																<th
																	scope="col"
																	className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
																>
																	Action
																</th>
															</tr>
															</thead>

															<tbody className="divide-y divide-gray-200 bg-white">
															{sendList &&
																sendList.length > 0 &&
																props.bookings.length > 0 &&
																sendList.map((res) => {
																	return (
																		<>
																			<tr key={res.a}>
																				<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
																					{res.i ? (
																						properties.map((p) => {
																							return (
																								<>
																									{p.value === res.i
																										? p.label
																										: ""}
																								</>
																							);
																						})
																					) : (
																						<>Not Assigned</>
																					)}
																				</td>
																				<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
																					{res.e}
																					<br/>
																					{
																						years.find(
																							(y) => y.value == res.af
																						)?.label
																					}
																					{res.aj == res.a && " (Active)"}
																				</td>
																				<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
																					{res.p}
																				</td>
																				<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
																					{res.b} {res.c} |{" "}
																					{res.n ? (
																						<TimeSince time={res.n}/>
																					) : (
																						" No Messages"
																					)}
																					{res.nn && (
																						<>
																							&nbsp;|&nbsp;
																							<TimeSince time={res.nn}/>
																						</>
																					)}
																				</td>
																				<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
																					{res.g === 1 ? "Closed" : "Open"}
																				</td>
																				{props.type === "collections" ? (
																					<>
																						<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
																							{res.ac === 1 ? "ON" : "OFF"}
																						</td>
																					</>
																				) : (
																					""
																				)}
																				<td className="text-center">
																					<input
																						type="checkbox"
																						onChange={(e) =>
																							changeList(e, res)
																						}
																						checked={res.checked}
																					/>
																				</td>
																			</tr>
																			<tr style={{borderTop: "0"}}>
																				<td
																					colSpan="6"
																					className="py-2 pl-6 text-sm font-medium text-gray-900"
																				>
																					Last Message:{" "}
																					<span
																						className="text-sm font-normal text-gray-500">
																					  {res.o}
																					</span>
																				</td>
																			</tr>
																		</>
																	);
																})}
															</tbody>
														</table>
													</div>

													<div
														className="px-6 py-4 ml-auto flex justify-between items-center space-x-5 bg-dark_background rounded-b-3xl">
														<div className="flex"></div>
														<div className="pr-4 ">
															<SubmitButton
																name="Submit"
																disabled={!isValid || isSubmitting || !dirty}
																isSubmitting={isSubmitting}
																text="Process"
															/>
														</div>
													</div>
												</Form>
											)}
										</Formik>
									</ErrorBoundary>
								</Dialog.Panel>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
