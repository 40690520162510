import React, {useEffect, Fragment, useState} from "react";
import {closeModal} from "../../store/modal/modalReducer";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {Dialog, Transition} from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import {Formik, Form} from "formik";
import SettingsSelect from "../Forms/SettingsSelect";
import CustomButton from "../Helpers/CustomButton";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLoader, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {SvgLoader, SvgProxy} from "react-svgmt";
import {getBooking} from "../../store/booking/bookingActions";

const usePersistentMapHeight = (initialHeight) => {
	const [height, setHeight] = useState(() => {
		const saved = localStorage.getItem('svgMapHeight');
		return saved || initialHeight;
	});

	useEffect(() => {
		localStorage.setItem('svgMapHeight', height);
	}, [height]);

	return [height, setHeight];
};

export default function ModalBedAssignment(props) {
	const dispatch = useDispatch();
	const [open] = useState(true);
	const [isFullyLoaded, setIsFullyLoaded] = useState(false);
	const [loadingMap, setLoadingMap] = useState(false);
	const [map, setMap] = useState(
		props.current_booking?.floor_id > 0 ? props.current_booking?.floor_id : ""
	);
	const [unitTypes, setUnittypes] = useState([]);
	const [floors, setFloors] = useState([]);
	const [chosenProperty, setChosenProperty] = useState(
		props.current_booking.property_id > 0
			? props.current_booking.property_id
			: 0
	);
	const {properties} = useSelector((state) => state.properties);
	const {settings} = useSelector((state) => state.settings);
	const [blocked, setBlocked] = useState("");
	const [moved_in_blocked, setMoved_in_blocked] = useState("");
	const [chosenBed, setChosenBed] = useState(0);
	const {current_staff} = useSelector((state) => state.auth);
	const [currentBed, setCurrentBed] = useState(0);
	const [reservedClick, setReservedClick] = useState(false);
	const [bedLoading, setBedLoading] = useState(false);

	const [bookingsActive, setBookingsActive] = useState([]);
	const [waitlist, setWaitlist] = useState([]);
	const [bedName, setBedName] = useState([]);
	const [oldBedName, setOldBedName] = useState([]);
	const [oldWaitBedName, setOldWaitBedName] = useState([]);
	const [booked, setBooked] = useState("");
	const [waitlisted, setWaitlisted] = useState("");
	const [loading_1, setLoading_1] = useState(false);
	const [loading_3, setLoading_3] = useState(false);
	const [loading_4, setLoading_4] = useState(false);
	const [loading_5, setLoading_5] = useState(false);
	const [unitGenders, setUnitGenders] = useState({});
	const [svgMapHeight, setSvgMapHeight] = usePersistentMapHeight("80");

	const genders = [
		{label: "Choose an option", value: 0},
		{label: "Male", value: 1},
		{label: "Female", value: 2},
	];

	const fetchFloorUnitGenders = async (floorId) => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}get_floor_beds_gender/${floorId}`);

			const unitGenderMap = {};
			response.data.forEach(bed => {
				if (!unitGenderMap[bed.unit_id]) {
					unitGenderMap[bed.unit_id] = {
						male: 0,
						female: 0
					};
				}

				if (bed.gender === 'male') {
					unitGenderMap[bed.unit_id].male += 1;
				} else if (bed.gender === 'female') {
					unitGenderMap[bed.unit_id].female += 1;
				}
			});

			const finalUnitGenders = {};
			Object.entries(unitGenderMap).forEach(([unitId, counts]) => {
				if (counts.male > 0 && counts.female > 0) {
					finalUnitGenders[unitId] = 'mixed';
				} else if (counts.male > 0) {
					finalUnitGenders[unitId] = 'male';
				} else if (counts.female > 0) {
					finalUnitGenders[unitId] = 'female';
				} else {
					finalUnitGenders[unitId] = 'default';
				}
			});

			setUnitGenders(finalUnitGenders);
		} catch (error) {
			console.error("Failed to fetch floor unit genders:", error);
		}
	};

	useEffect(async () => {
		if (chosenProperty > 0) {
			setLoadingMap(true);
			setIsFullyLoaded(false);

			let gender = props.current_booking?.user?.profile?.gender;

			if (current_staff.id === 1
				|| current_staff?.email === "kim@urbancircle.co.za"
				|| current_staff?.email === "michelle@urbancircle.co.za"
				|| current_staff?.email === "rowan@qholdings.co.za"
				|| current_staff?.email === "resileads@qholdings.co.za") {
				gender = -1;
			}

			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}get_property_floors/${chosenProperty}/${gender}`,
			})
				.then((response) => {
					setFloors(response.data.floors);
					setUnittypes(response.data.unit_types);
				})
				.catch((error) => {
					alert("properties failed");
				});
		}

		if (map > 0) {
			setLoadingMap(true);
			setIsFullyLoaded(false);
			setLoading_1(false);
			setLoading_3(false);
			setLoading_4(false);
			setLoading_5(false);

			try {
				// Execute all API calls in parallel for better performance
				const [blockedResponse, movedInResponse, bookedResponse, waitlistedResponse] = await Promise.all([
					axios.get(`${process.env.REACT_APP_API_URL}get_floor_bed_bookings/${map}/1/${props.current_booking?.year_id}/0`),
					axios.get(`${process.env.REACT_APP_API_URL}get_floor_bed_bookings/${map}/5/${props.current_booking?.year_id}/0`),
					axios.get(`${process.env.REACT_APP_API_URL}get_floor_bed_bookings/${map}/3/${props.current_booking?.year_id}/0`),
					axios.get(`${process.env.REACT_APP_API_URL}get_floor_bed_bookings/${map}/4/${props.current_booking?.year_id}/0`)
				]);

				let var_blocked = ".blocked";
				if (blockedResponse.data !== "") {
					var_blocked += "," + blockedResponse.data;
				}
				setBlocked(var_blocked);

				let var_moved_in_blocked = ".movedIn";
				if (movedInResponse.data !== "") {
					var_moved_in_blocked += "," + movedInResponse.data;
				}
				setMoved_in_blocked(var_moved_in_blocked);

				setBooked(bookedResponse.data);
				setWaitlisted(waitlistedResponse.data);

				// Add this line to fetch unit genders
				await fetchFloorUnitGenders(map);

			} catch (error) {
				alert("Failed to load bed data");
			} finally {
				setLoading_1(true);
				setLoading_3(true);
				setLoading_4(true);
				setLoading_5(true);
				setIsFullyLoaded(true);
				setLoadingMap(false);
			}
		}

		setCurrentBed(
			props.current_booking.bed_id
				? props.current_booking.bed_id
				: props.current_booking.waiting_bed_id
		);
	}, [chosenProperty, map]);

	async function getBedInfo(id) {
		try {
			// Get current user's gender from props.current_booking
			const currentUserGender = props.current_booking?.user_profile?.gender;

			// First bed info call
			const bedInfoResponse = await axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}get_bed_info/${id}`,
			});

			// Check gender compatibility
			const unitGenderInfo = bedInfoResponse.data.unit_gender_info;

			if (unitGenderInfo && currentUserGender) {
				// If unit has occupants, check gender compatibility
				if (unitGenderInfo.total_occupants > 0) {
					// For male trying to enter female unit
					if (currentUserGender === 1 && unitGenderInfo.female_count > 0) {
						throw new Error("Cannot allocate: This is a female-only unit");
					}
					// For female trying to enter male unit
					if (currentUserGender === 2 && unitGenderInfo.male_count > 0) {
						throw new Error("Cannot allocate: This is a male-only unit");
					}
				}
			}

			setBedName(bedInfoResponse.data);

			// Check waiting bed if exists
			if (props.current_booking.waiting_bed_id > 0) {
				const waitBedResponse = await axios({
					method: "get",
					url: `${process.env.REACT_APP_API_URL}get_bed_info/${props.current_booking.waiting_bed_id}`,
				});
				setOldWaitBedName(waitBedResponse.data);
			}

			// Get active bookings
			const activeBookingsResponse = await axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}get_bed_bookings/${id}/2/${props.current_booking?.year_id}`,
			});
			setBookingsActive(activeBookingsResponse.data);

			// Get waitlist
			const waitlistResponse = await axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}get_bed_bookings/${id}/3/${props.current_booking?.year_id}`,
			});
			setWaitlist(waitlistResponse.data);

		} catch (error) {
			if (error.message.includes('Cannot allocate')) {
				alert(error.message);
				setChosenBed(0); // Reset chosen bed
				return false; // Return false to indicate allocation not possible
			} else {
				alert("bed load failed");
				console.error(error);
			}
		}
	}

	// Optional: Add a visual indicator component
	const GenderIndicator = ({unitGenderInfo}) => {
		if (!unitGenderInfo || unitGenderInfo.total_occupants === 0) {
			return <span>Empty Unit</span>;
		}

		return (
			<div className="gender-indicator">
				<span>Unit Status: {unitGenderInfo.dominant_gender} only</span>
				<span>({unitGenderInfo.male_count} males, {unitGenderInfo.female_count} females)</span>
			</div>
		);
	};

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			boxShadow: "none",
			border:
				state.isFocused && "none" ? "1px solid #9F7D38" : "1px solid #d1d5db",
			cursor: "pointer",
			"&:hover": {
				border: state.isFocused ? "1px solid #9F7D38" : "1px solid #d1d5db",
				cursor: "pointer",
			},
			borderRadius: "10px",
			padding: 0,
		}),
		container: (provided, state) => ({
			...provided,
			padding: 0,
			border: 0,
		}),
		dropdownIndicator: (provided, state) => ({
			...provided,
			color: "#e7ebe5",
		}),
	};

	const set_new_bed = async (bedId) => {
		setBedLoading(true);

		const currentUserGender = props.current_booking?.user?.profile?.gender;

		try {
			const bedInfoResponse = await axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}get_bed_info/${bedId}`,
			});

			const unitGenderInfo = bedInfoResponse.data.unit_gender_info;

			// Gender validation
			if (unitGenderInfo.total_occupants > 0) {
				if (currentUserGender === 1 && unitGenderInfo.female_count > 0) {
					throw new Error("Cannot allocate: This is a female-only unit");
				}
				if (currentUserGender === 2 && unitGenderInfo.male_count > 0) {
					throw new Error("Cannot allocate: This is a male-only unit");
				}
			}

			// If gender check passes, proceed with bed allocation
			await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_URL}change_user_bed`,
				data: {
					booking_id: props.current_booking.id,
					year: props.current_booking.year_id,
					bed_id: parseInt(bedId),
					user: current_staff,
					type: props.type,
					tag_id: props.tag_id,
				},
			})
				.then((response) => {
					dispatch(getBooking(props.current_booking.id));
					setChosenBed(0);
					dispatch(closeModal());
				})
				.catch((error) => {
					alert("properties failed");
				});

			// If successful, update UI
			setChosenBed(0);
			if (props.onSuccess) {
				props.onSuccess();
			}

		} catch (error) {
			alert(error.message);
			setChosenBed(0);
		} finally {
			setBedLoading(false);
		}
	};

	function set_new_waiting_bed(waiting_bed_id) {
		if (
			current_staff.isSuper == 1 ||
			current_staff.email == "rowan@qholdings.co.za"
		) {
			axios({
				method: "post",
				url: `${process.env.REACT_APP_API_URL}change_user_waiting_bed`,
				data: {
					booking_id: props.current_booking.id,
					waiting_bed_id: parseInt(waiting_bed_id),
					user: current_staff,
				},
			})
				.then((response) => {
					dispatch(getBooking(props.current_booking.id));
					setChosenBed(0);
					dispatch(closeModal());
				})
				.catch((error) => {
					console.log(error);
					alert("properties failed");
				});
		} else {
			alert("Access Denised");
		}
	}

	async function set_new_primary_bed(primary_bed_id) {
		if (
			current_staff.isSuper == 1 ||
			current_staff.email == "rowan@qholdings.co.za"
		) {
			await axios({
				method: "post",
				url: `${process.env.REACT_APP_API_URL}change_user_primary_bed`,
				data: {
					booking_id: props.current_booking.id,
					primary_bed_id: parseInt(primary_bed_id),
					user: current_staff,
				},
			})
				.then((response) => {
					dispatch(getBooking(props.current_booking.id));
					setChosenBed(0);
					dispatch(closeModal());
				})
				.catch((error) => {
					console.log(error);
					alert("properties failed");
				});
		} else {
			alert("Access Denised");
		}
	}

	const GenderStatusBadge = ({unitGenderInfo}) => {
		if (!unitGenderInfo || unitGenderInfo.total_occupants === 0) {
			return null;
		}

		const getBadgeClasses = () => {
			if (unitGenderInfo.male_count > 0 && unitGenderInfo.female_count > 0) {
				return 'bg-orange-100 text-orange-800';
			}
			return unitGenderInfo.male_count > 0
				? 'bg-blue-100 text-blue-800'
				: 'bg-pink-100 text-pink-800';
		};

		const getGenderText = () => {
			if (unitGenderInfo.male_count > 0 && unitGenderInfo.female_count > 0) {
				return 'Mixed Gender';
			}
			return unitGenderInfo.male_count > 0 ? 'Male Only' : 'Female Only';
		};

		return (
			<span className={`px-2 py-1 rounded-full text-xs font-bold ${getBadgeClasses()}`}>
            {getGenderText()}
        </span>
		);
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-50 inset-0 overflow-y-auto"
				open={open}
				onClose={() => dispatch(closeModal())}
			>
				<div
					className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					  </span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<ErrorBoundary>
							<div
								className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[90%] sm:w-full sm:p-6">
								<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
									<CustomButton
										type="secondary"
										className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
										onClick={() => dispatch(closeModal())}
									>
										Close
									</CustomButton>
								</div>

								<div className="w-full">
									<div className="flex justify-between mb-4 items-center">
										<div>
											<h2 className="font-bold text-lg">
												{props.current_booking.user.name}{" "}
												{props.current_booking.user.surname}
											</h2>
										</div>

										<button
											className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
											onClick={() => dispatch(closeModal())}
										>
											CANCEL
										</button>
									</div>

									{/* {chosenBed > 0 ? ( */}
									{chosenBed > 0 && !reservedClick ? (
										<div>
											{props.current_booking.waiting_bed_id > 0 && (
												<div>
													  <span>
														This will remove the user from the waiting list for
														bed
													  </span>{" "}
													{/* {props.current_booking.waiting_bed_id} */}
													{oldWaitBedName && oldWaitBedName.name ? (
														<>
															{oldWaitBedName.property.name}{" "}
															{oldWaitBedName.unit.name} {oldWaitBedName.name}
														</>
													) : (
														""
													)}
												</div>
											)}

											<div>
												Change to bed{" "}
												{bedName && bedName.name ? (
													<>
														{bedName.property.name} {bedName.unit.name}{" "}
														<span>{bedName.name}</span>
														<GenderStatusBadge unitGenderInfo={bedName.unit_gender_info}/>?
													</>
												) : (
													""
												)}
											</div>

											<div className="space-x-2 mt-4">
												<button
													className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
													onClick={() => set_new_bed(chosenBed)}
													disabled={bedLoading}
												>
													{bedLoading ? (
														<>
															<FontAwesomeIcon
																className="w-4 h-4 animate-spin"
																icon={faLoader}
															/>
															<span className="w-full">
																{props.loadingtext
																	? props.loadingtext
																	: "Loading"}
															</span>
														</>
													) : (
														"Yes"
													)}
												</button>
												<button
													className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
													onClick={() => setChosenBed(0)}
												>
													No
												</button>

												{props.current_booking.waiting_bed_id ===
												parseFloat(chosenBed) ? (
													<>
														<button
															className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
															onClick={() => set_new_primary_bed(chosenBed)}
														>
															Make Primary
														</button>
													</>
												) : (
													""
												)}
											</div>
										</div>
									) : chosenBed > 0 && reservedClick ? (
										// props.current_booking.waiting_bed_id > 0 && (
										<div>
											{props.current_booking.waiting_bed_id > 0 && (
												<div>
                          <span>
                            This will remove the user from the waiting list for
                            bed
                          </span>{" "}
													{/* {props.current_booking.waiting_bed_id} */}
													{oldWaitBedName && oldWaitBedName.name ? (
														<>
															{oldWaitBedName.property.name}{" "}
															{oldWaitBedName.unit.name} {oldWaitBedName.name}
														</>
													) : (
														""
													)}
												</div>
											)}
											{props.current_booking.bed_id > 0 && (
												<div>
													<span>This will remove the user from bed</span>{" "}
													{props.current_booking.bed_id}{" "}
													<span>and add them to the waiting list for</span>{" "}
													{chosenBed}
												</div>
											)}
											{/* <div>Change to waitlist for bed {chosenBed}?</div> */}

											<div>
												Change to waitlist for bed{" "}
												{bedName && bedName.name ? (
													<>
														{bedName.property.name} {bedName.unit.name}{" "}
														{bedName.name}?
													</>
												) : (
													""
												)}
												{/* {chosenBed}*/}
											</div>

											<div className="space-x-2 mt-4">
												<button
													className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
													onClick={() => set_new_waiting_bed(chosenBed)}
												>
													Yes
												</button>
												<button
													className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
													onClick={() => setChosenBed(0)}
												>
													No
												</button>

												{props.current_booking.waiting_bed_id ===
												parseFloat(chosenBed) ? (
													<>
														<button
															className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
															onClick={() => set_new_primary_bed(chosenBed)}
														>
															Make Primary
														</button>
													</>
												) : (
													""
												)}
											</div>
										</div>
									) : (
										<Formik
											initialValues={{
												property_id: props.current_booking.property_id,
												room_type: props.current_booking?.unit_type?.id
													? props.current_booking?.unit_type?.id
													: props.current_booking?.unit_type
														? props.current_booking?.unit_type
														: "",
												floor_id: props.current_booking.floor_id,
											}}
											onSubmit={async (
												values,
												{setSubmitting, setErrors, resetForm}
											) => {
												setMap(values.floor_id);
											}}
										>
											{({
												  values,
												  touched,
												  errors,
												  setFieldValue,
												  setFieldTouched,
												  handleChange,
												  handleBlur,
												  handleSubmit,
												  isSubmitting,
												  isValid,
												  dirty,
											  }) => (
												<Form onSubmit={handleSubmit}>
													<div
														className="colour-uc6 rounded-3xl py-7 px-20 flex justify-between items-center mb-4">
														<div className="flex w-4/5 flex-wrap">
															<div className="w-1/3 px-3 z-20">
																<div>
																	<label
																		htmlFor="unit_id"
																		className="text-sm font-medium text-gray-700"
																	>
																		Property
																	</label>
																	<Select
																		id="property_id"
																		name="property_id"
																		options={properties}
																		onChange={(val) => {
																			setFieldValue("property_id", val.value);
																			setChosenProperty(val.value);

																			setFieldValue("floor_id", 0);
																			setFieldValue("room_type", 0);
																		}}
																		onBlur={setFieldTouched}
																		value={
																			properties && properties.length > 0
																				? properties.find(
																					(option) =>
																						option.value ===
																						values.property_id
																				)
																				: ""
																		}
																		components={{
																			IndicatorSeparator: () => null,
																		}}
																		styles={customStyles}
																		className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
																	/>
																	<p className="text-red-400 text-sm">
																		{errors.property_id}
																	</p>
																</div>
															</div>

															<div className="w-1/3 px-3 z-20">
																<SettingsSelect
																	label="ROOM TYPE"
																	name="room_type"
																	required
																	onChange={setFieldValue}
																	onBlur={setFieldTouched}
																	value={values.room_type}
																	touched={touched.room_type}
																	error={errors.room_type}
																	options={unitTypes}
																/>
															</div>

															<div className="w-1/3 px-3 z-20">
																<SettingsSelect
																	label="FLOOR"
																	name="floor_id"
																	required
																	onChange={setFieldValue}
																	onBlur={setFieldTouched}
																	value={values.floor_id}
																	touched={touched.floor_id}
																	error={errors.floor_id}
																	options={floors}
																/>
															</div>
															{props.current_booking.user?.profile?.gender >
																0 &&
																props.current_booking.unit?.floor?.id > 0 &&
																props.current_booking.unit?.floor?.gender !=
																props.current_booking.user?.profile
																	?.gender && (
																	<>
																		<h3 className="text-red-700 font-bold">
																			This user is currently assigned to a bed
																			that doesn't match their gender.
																		</h3>
																		<p>
																			To reassign them to a matching floor, use
																			the search button to find them a suitable
																			bed.
																		</p>
																	</>
																)}
														</div>

														<div>
															<button
																type="submit"
																className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
															>
																SEARCH
															</button>

															{current_staff?.id === 1 && (
																<div className="max-w-60">
																	<label>Zoom Level (80 = 80% height)</label>
																	<input
																		type="text"
																		name="svg_map_height"
																		value={svgMapHeight}
																		onChange={(e) => setSvgMapHeight(e.target.value)}
																		className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
																	/>
																</div>
															)}
														</div>
														<div>
															{props.current_booking.waiting_bed_id > 0 && (
																<h2 className="">
																	This user is on a waitlist for the purple bed
																	(or similar).
																</h2>
															)}
														</div>
													</div>

													{map !== "" && (
														<div className="relative">
															{loadingMap && (
																<div
																	className="absolute inset-0 bg-white bg-opacity-75 z-50 flex items-center justify-center">
																	<div className="text-center">
																		<FontAwesomeIcon
																			className="w-12 h-12 animate-spin text-primary mb-4"
																			icon={faLoader}
																		/>
																		<p className="text-lg font-medium">Loading bed
																			data...</p>
																		<p className="text-sm text-gray-500">Please wait
																			while we fetch all bed information</p>
																	</div>
																</div>
															)}

															{isFullyLoaded && blocked !== "" && moved_in_blocked !== "" && (
																<>
																	<div
																		className="flex justify-center items-center space-x-12">
																		{process.env.REACT_APP_COLOUR === "UC" ? (
																			<div
																				className="flex space-x-2 justify-center items-center">
																				<div
																					className="w-5 h-5 rounded-full"
																					style={{backgroundColor: "black"}}
																				></div>
																				<div className="font-bold">
																					{loading_1 === false
																						? "Loading..."
																						: "Reserved / Blocked"}
																				</div>
																			</div>
																		) : (
																			<div
																				className="flex space-x-2 justify-center items-center">
																				<div
																					className="w-5 h-5 rounded-full"
																					style={{backgroundColor: "#c9c9c9"}}
																				></div>
																				<div className="font-bold">Occupied
																				</div>
																			</div>
																		)}

																		<div
																			className="flex space-x-2 justify-center items-center">
																			<div
																				className="w-5 h-5 rounded-full"
																				style={{backgroundColor: "blue"}}
																			></div>
																			<div className="font-bold">
																				{process.env.REACT_APP_COLOUR === "UC"
																					? loading_3 === false
																						? "Loading..."
																						: "New Leads"
																					: "Last month of lease"}
																			</div>
																		</div>

																		<div
																			className="flex space-x-2 justify-center items-center">
																			<div
																				className="w-5 h-5 rounded-full"
																				style={{backgroundColor: "purple"}}
																			></div>
																			<div className="font-bold">
																				{process.env.REACT_APP_COLOUR === "UC"
																					? loading_4 === false
																						? "Loading..."
																						: "Renewal"
																					: "2nd last month of lease"}
																			</div>
																		</div>

																		<div
																			className="flex space-x-2 justify-center items-center">
																			<div
																				className="w-5 h-5 rounded-full"
																				style={{backgroundColor: "deeppink"}}
																			></div>
																			<div className="font-bold">
																				{process.env.REACT_APP_COLOUR === "UC"
																					? loading_5 === false
																						? "Loading..."
																						: "Moved In"
																					: "Overlapping Leases"}
																			</div>
																		</div>

																		<div
																			className="flex space-x-2 justify-center items-center">
																			<div
																				className="w-5 h-5 rounded-full"
																				style={{backgroundColor: "green"}}
																			></div>
																			<div className="font-bold">
																				{process.env.REACT_APP_COLOUR === "UC"
																					? "Mapped"
																					: "Vacant"}
																			</div>
																		</div>

																		{process.env.REACT_APP_COLOUR === "QL" ? (
																			<div
																				className="flex space-x-2 justify-center items-center">
																				<div
																					className="w-5 h-5 rounded-full"
																					style={{backgroundColor: "#757575"}}
																				></div>
																				<div className="font-bold">
																					Lease expired M2M
																				</div>
																			</div>
																		) : (
																			""
																		)}

																		{process.env.REACT_APP_COLOUR === "UC" ? (
																			<div
																				className="flex space-x-2 justify-center items-center">
																				<div
																					className="w-5 h-5 rounded-full"
																					style={{backgroundColor: "#e5e7eb"}}
																				></div>
																				<div className="font-bold">Unmapped
																				</div>
																			</div>
																		) : (
																			""
																		)}
																	</div>

																	<div
																		style={{height: `${svgMapHeight}vh`}}
																		className="map_svg"
																	>
																		<SvgLoader
																			path={
																				process.env.REACT_APP_LARAVEL_URL +
																				"api/get_svg/" +
																				map + "?v=" + Math.random()
																			}
																			style={{
																				width: `${svgMapHeight}vw`,
																				height: `${svgMapHeight}vh`
																			}}
																		>
																			<SvgProxy
																				selector=".mapped"
																				fill="rgb(227,227,227)"
																				onClick={(e) => {
																					if (
																						current_staff.isSuper == 1 ||
																						current_staff.email ==
																						"rowan@qholdings.co.za" ||
																						current_staff.email ==
																						"Phumzile@urbancircle.co.za"
																					) {
																						setChosenBed(
																							e.currentTarget.getAttribute(
																								"bedid"
																							)
																						);
																						setReservedClick(false);
																						getBedInfo(
																							e.currentTarget.getAttribute(
																								"bedid"
																							)
																						);
																					} else {
																						alert("Access denied");
																					}
																				}}
																			/>
																			<SvgProxy
																				selector=".room > g > rect.room_inner.highlighted, .room > g > path.room_inner.highlighted, .room
                                        > g > rect.room_container.highlighted, .room > g > path.room_container.highlighted"
																				fill="#2ceaeb"
																			/>
																			<SvgProxy
																				selector=".taken"
																				fill="#ff0000"
																			/>

																			{booked != "" && (
																				<SvgProxy
																					selector={booked}
																					fill="blue"
																					onClick={() =>
																						alert(
																							"A new lead has this bed already!"
																						)
																					}
																				/>
																			)}

																			{waitlisted != "" && (
																				<SvgProxy
																					selector={waitlisted}
																					fill="purple"
																					onClick={() =>
																						alert(
																							"A renewal has this bed already!"
																						)
																					}
																				/>
																			)}

																			{blocked != "" && (
																				<SvgProxy
																					selector={blocked}
																					fill="black"
																					onClick={() =>
																						alert(
																							"A lease has already been signed for this bed!"
																						)
																					}
																				/>
																			)}

																			<SvgProxy
																				selector=".resident_reserved"
																				fill="black"
																			/>

																			<SvgProxy selector=".blocked" fill="red"
																					  onClick={() => alert("blocked")}/>
																			<SvgProxy
																				selector=".blocked.yellow"
																				fill="yellow"
																				onClick={(e) => {
																					if (current_staff.id === 1
																						|| current_staff?.email === "kim@urbancircle.co.za"
																						|| current_staff?.email === "michelle@urbancircle.co.za"
																						|| current_staff?.email === "rowan@qholdings.co.za"
																						|| current_staff?.email === "resileads@qholdings.co.za") {
																						setChosenBed(
																							e.currentTarget.getAttribute(
																								"bedid"
																							)
																						);
																						setReservedClick(false);
																						getBedInfo(
																							e.currentTarget.getAttribute(
																								"bedid"
																							)
																						);
																					} else {
																						alert("Blocked");
																					}
																				}}
																			/>

																			<SvgProxy
																				selector={moved_in_blocked}
																				fill="deeppink"
																				onClick={() => alert("blocked")}
																			/>

																			<SvgProxy
																				selector={
																					"#bed_" + props.current_booking?.bed?.id
																				}
																				fill="green"
																			/>

																			{/*<SvgProxy*/}
																			{/*	selector={*/}
																			{/*		"#bed_" +*/}
																			{/*		props.current_booking.waiting_bed_id*/}
																			{/*	}*/}
																			{/*	fill="purple"*/}
																			{/*/>*/}

																			<SvgProxy
																				selector=".room"
																				onElementSelected={(elements) => {
																					Array.from(elements).forEach(element => {
																						if (element.classList.contains('room')) {
																							const unitId = element.getAttribute('unitid');
																							const gender = unitGenders[unitId];

																							// Define colors object
																							const colors = {
																								male: {
																									fill: 'rgb(121,169,242)',
																									stroke: '#4A75B8'
																								},
																								female: {
																									fill: 'rgb(242,121,151)',
																									stroke: '#B84A6E'
																								},
																								mixed: {
																									fill: '#FFA500', // Orange for mixed gender
																									stroke: '#CC8400' // Darker orange for stroke
																								},
																								default: {
																									fill: '#E0E0E0',
																									stroke: '#A0A0A0'
																								}
																							};

																							// Get the appropriate colors
																							const colorSet = colors[gender] || colors.default;

																							// Apply the colors
																							element.style.fill = colorSet.fill;
																							element.style.stroke = colorSet.stroke;

																							// Add thicker stroke for mixed units
																							if (gender === 'mixed') {
																								element.style.strokeWidth = '2';
																							}
																						}
																					});
																				}}
																			/>
																		</SvgLoader>
																	</div>
																</>
															)}
														</div>
													)}
												</Form>
											)}
										</Formik>
									)}

									{chosenBed &&
									chosenBed > 0 &&
									(bookingsActive.length > 0 || waitlist.length > 0) ? (
										<>
											<h2 className="bg-gray-200 mt-5 p-5 text-lg font-medium border-indigo-600 border-x-2 border-t-2">
												Current Bed Bookings
											</h2>
											<div
												className="flex bg-gray-200 px-5 border-indigo-600 border-x-2 border-b-2 mb-2">
												<div className="w-1/2">
													<h2 className="text-lg font-medium text-primary mb-4">
														Booking History
													</h2>

													{bookingsActive?.map((b) => (
														<div className="mb-4">
															<div key={b.id}>
																{b.booking_reference} {b.user?.name}
																<br/>
																Paid:{" "}
																{b.rates?.length > 0
																	? b.rates[b.rates.length - 1].paid === 1
																		? "Yes"
																		: "No"
																	: "No"}
																<br/>
																Lease Signed:{" "}
																{b.rates?.length > 0
																	? b.rates[b.rates.length - 1]
																		.signed_lease_at !== ""
																		? b.rates[b.rates.length - 1]
																			.signed_lease_at
																		: "No"
																	: "No"}
																<br/>
															</div>
														</div>
													))}
												</div>

												<div className="w-1/2">
													<h2 className="text-lg font-medium text-primary mb-4">
														Waiting List
													</h2>
													{waitlist?.map((b) => (
														<div className="mb-4">
															<div key={b.id}>
																{b.booking_reference ===
																props.current_booking.booking_reference ? (
																	<>
                                    <span className="text-green-600 font-bold">
                                      {b.booking_reference} {b.user?.name}
                                    </span>
																	</>
																) : (
																	<>
																		{b.booking_reference} {b.user?.name}
																	</>
																)}
																<br/>
																Paid:{" "}
																{b.rates?.length > 0
																	? b.rates[b.rates.length - 1].paid === 1
																		? "Yes"
																		: "No"
																	: "No"}
																<br/>
																Lease Signed:{" "}
																{b.rates?.length > 0
																	? b.rates[b.rates.length - 1]
																		.signed_lease_at !== ""
																		? b.rates[b.rates.length - 1]
																			.signed_lease_at
																		: "No"
																	: "No"}
																<br/>
															</div>
														</div>
													))}
												</div>
											</div>
										</>
									) : (
										""
									)}
								</div>
							</div>
						</ErrorBoundary>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
