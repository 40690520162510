import React, {useEffect, useState, useCallback} from "react";
import {useParams, Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {openModal} from "../../store/modal/modalReducer";
import CustomButton from "../../components/Helpers/CustomButton";

export default function Properties2Page() {
	const {property_id} = useParams();
	const {current_staff} = useSelector((state) => state.auth);
	const {settings} = useSelector((state) => state.settings);

	const dispatch = useDispatch();
	const history = useHistory();

	const [property, setProperty] = useState({});
	const [floors, setFloors] = useState([]);
	const [unitTypes, setUnitTypes] = useState([]);
	const [pricedPerFloor, setPricedPerFloor] = useState([]);

	const [loading, setLoading] = useState(false);
	const [allowClick, setAllowClick] = useState(true);

	useEffect(async () => {
		setLoading(true);

		if (property_id > 0 && floors?.length === 0) {
			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}property_floors/${property_id}`,
			})
				.then((response) => {
					setFloors(response.data.floors);
					setUnitTypes(response.data.unit_types);
					setProperty(response.data.property);
					setPricedPerFloor(response.data.beds_priced);
				})
				.catch((error) => {
					alert("floors failed");
				});
		}

		if (history.action == "POP") {
			if (allowClick === true) {
				axios({
					method: "get",
					url: `${process.env.REACT_APP_API_URL}channels`,
				});
				axios({
					method: "get",
					url: `${process.env.REACT_APP_API_URL}tagsList`,
				});
				axios({
					method: "get",
					url: `${process.env.REACT_APP_API_URL}collectionList`,
				});
				axios({
					method: "get",
					url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
				});

				if (settings && settings.brokers === 1) {
					axios({
						method: "get",
						url: `${process.env.REACT_APP_API_URL}brokers`,
					});
				}

				if (settings && settings.renewals === 1) {
					axios({
						method: "get",
						url: `${process.env.REACT_APP_API_URL}renewalsItem`,
					});
				}

				setAllowClick(false);
			}
		}
	}, []);

	return (
		<>
			<div className="h-screen w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
				<div className="flex justify-between">
					<h1>{property?.name}</h1>

					{current_staff?.status === "Admin" && (
						<button
							className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
							onClick={() =>
								dispatch(
									openModal({
										modal_type: "ModalProperty",
										modal_props: {
											property_id: property_id,
											property: property,
										},
									})
								)
							}
						>
							Edit Property
						</button>
					)}
					<Link
						to="/properties/step1"
						className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
					>
						Back to Properties
					</Link>
				</div>

				<div className="flex justify-between my-6">
					<h2 className="bold">Floors</h2>

					{current_staff?.status === "Admin" && (
						<button
							className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
							onClick={() =>
								dispatch(
									openModal({
										modal_type: "ModalPropFloor",
										modal_props: {
											property_id: property_id,
											id: 0,
										},
									})
								)
							}
						>
							Add a Floor
						</button>
					)}
				</div>

				<table className="min-w-full divide-y divide-gray-300">
					<thead className="bg-gray-50">
					<tr>
						<th scope="col"
							className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
							Floor Name
						</th>
						<th scope="col"
							className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
							Gender
						</th>
						<th scope="col"
							className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
							Sort Order
						</th>
						<th scope="col"
							className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
							Year (Leave blank for all years)
						</th>
						<th scope="col"
							className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
							Has Map
						</th>
						<th scope="col"
							className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
							Beds Individually Priced
						</th>
						<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
							<span className="sr-only">Edit</span>
						</th>
					</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 bg-white">
					{floors?.map((f) => (
						<tr key={f.id}>
							<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  sm:pl-6">{f.name}</td>
							<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  sm:pl-6">{f.gender === 1 ? "Male" : f.gender === 2 ? "Female" : "Unisex"}</td>
							<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  sm:pl-6">{f.sort_order}</td>
							<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  sm:pl-6">{f.year === 0 ? "Default" : f.year}</td>
							<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  sm:pl-6">{f.map_upload === "" ? "No" : "Yes"}</td>
							{/* pricedPerFloor */}
							<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  sm:pl-6">
								{pricedPerFloor &&
									pricedPerFloor.length > 0 &&
									pricedPerFloor.map((priced) => {
										if (priced.floor_id == f.id) {
											return (
												<>
													{priced.year_id == 0 ? "All Years" : priced.year_id == 1 ? "2023" : "2024"} : {priced.total}
													<br/>
												</>
											);
										}
									})}
							</td>
							<td className="space-x-4" align="right">
								{current_staff?.status === "Admin" && (
									<button
										onClick={() =>
											dispatch(
												openModal({
													modal_type: "ModalPropFloor",
													modal_props: {
														property_id: property_id,
														id: f.id,
													},
												})
											)
										}
										className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
									>
										Edit Floor
									</button>
								)}

								<Link
									to={"/properties/step3/" + property_id + "/" + f.id}
									className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
								>
									View Map
								</Link>
							</td>
						</tr>
					))}
					</tbody>
				</table>

				{current_staff?.status === "Admin" && (
					<>
						<div className="flex justify-between mt-10 mb-6">
							<h2 className="bold">Unit Types</h2>
							<button
								className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
								onClick={() =>
									dispatch(
										openModal({
											modal_type: "ModalPropUnitType",
											modal_props: {
												property_id: property_id,
												id: 0,
											},
										})
									)
								}
							>
								Add a Unit Type
							</button>
						</div>

						<table className="min-w-full divide-y divide-gray-300">
							<thead className="bg-gray-50">
							<tr>
								<th scope="col"
									className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
									Unit Type
								</th>
								<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
									<span className="sr-only">Edit</span>
								</th>
							</tr>
							</thead>
							<tbody className="divide-y divide-gray-200 bg-white">
							{unitTypes?.map((f) => (
								<tr key={f.id}>
									<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  sm:pl-6">{f.name}</td>
									<td align="right">
										<button
											className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
											onClick={() =>
												dispatch(
													openModal({
														modal_type: "ModalPropUnitType",
														modal_props: {
															property_id: property_id,
															id: f.id,
														},
													})
												)
											}
										>
											Edit Unit Type
										</button>
									</td>
								</tr>
							))}
							</tbody>
						</table>
					</>
				)}
			</div>
		</>
	);
}
