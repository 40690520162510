import React from "react";
import NavBar from "../components/Helpers/NavBar";
import {Route} from "react-router-dom";
import ModalManager from "../store/modal/modalManager";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";
import AppLoader from "../components/Helpers/AppLoader";
import "react-toastify/dist/ReactToastify.css";
import LoginForm from "./Auth/LoginForm";
import LoginGuestForm from "./Auth/LoginGuestForm";
import NotFound from "./NotFound";
import {useLocation} from "react-router-dom";

import BookingGrid from "./Booking/BookingGrid";
import EditUser from "./Settings/EditUser";
import EditUserUC from "./Settings/EditUserUC";
import Settings from "./Settings/SettingsPage";
import ManageUsers from "./Settings/ManageUsers";
import MetersPage from "./Admin/MetersPage";
import PrivateRoute from "../components/PrivateRoute";
import ApplicationForm from "../components/Applicant/ApplicationForm";
import ApplicationFormUCStep1Short from "../components/Applicant/UC/step1_short";
import ApplicationFormUCStep4 from "../components/Applicant/ApplicationFormUCStep4";
import ApplicationFormUCEdit from "../components/Applicant/ApplicationFormUCEdit";
import PaymentFormUC from "../components/Applicant/PaymentFormUC";
import DocumentsUC from "../components/Applicant/DocumentsUC";
import BankDetailsUC from "../components/Applicant/BankDetailsUC";
import BankDetailsQL from "../components/Applicant/BankDetailsQL";
import ForgetPassword from "./Auth/ForgetPassword";
import ResetPassword from "./Auth/ResetPassword";
import Properties1Page from "./Properties/Properties1Page";
import Properties2Page from "./Properties/Properties2Page";
import Properties3Page from "./Properties/Properties3Page";
import {TimeoutLogic} from "../components/Helpers/TimeoutLogic";
import NOKFormUC from "../components/Applicant/NOKFormUC";
import ReportsPage from "./Reports/ReportsPage";
import ReportEmailLogs from "./Reports/ReportEmailLogs";
import ReportExportAll from "./Reports/ReportExportAll";
import ReportApplications from "./Reports/ReportApplications";
import ReportTakeUp from "./Reports/ReportTakeUp";
import ReportVendor from "./Reports/ReportVendor";
import ReportsLeases from "./Reports/ReportsLeases";
import ReportsLeasesSigned from "./Reports/ReportsLeasesSigned";
import ReportsAdditionalInvoices from "./Reports/ReportsAdditionalInvoices";
import ReportBankDetailsChange from "./Reports/ReportBankDetailsChanges";
import ReportPayments from "./Reports/ReportPayments";
import ReportBedAllocation from "./Reports/ReportBedAllocation";
import ReportAllBeds from "./Reports/ReportAllBeds";
import ReportAnomolies from "./Reports/ReportAnomolies";
import ReportBedsCMM from "./Reports/ReportBedsCMM";
import ReportBedsByColour from "./Reports/ReportBedsByColour";
import ReportUserTracking from "./Reports/ReportUserTracking";
import EmailFixPage from "./Admin/Roger/EmailFixPage";
import DocumentsQL from "../components/Applicant/DocumentsQL";
import MoveInScheduler from "../components/Schedule/MoveInScheduler";
import MoveInConfirm from "../components/Schedule/MoveInConfirm";
import BookingCalendar from "./Calendar/Calendar";
import ApplicationFormQP from "../components/Applicant/ApplicationFormQP";
import EditUserQP from "./Settings/EditUserQP";
import OrdersPage from "./Orders/OrdersPage";
import OrdersPricing from "./Orders/OrdersPricing";
import ReportMove from "./Reports/ReportMove";
import ReportsMentions from "./Reports/ReportsMentions";
import TagManager from "react-gtm-module";
import PayFastPageUC from "../components/Applicant/PayFastPageUC";
import ReportViewings from "./Reports/ReportViewings";
import ReportKeyword from "./Reports/ReportKeyword";
import ReportsCancellation from "./Reports/ReportsCancellation";
import ReportChatAudit from "./Reports/ReportChatAudit";
import ReportUserActivity from "./Reports/ReportUserActivity";
import ReportChatMovement from "./Reports/ReportChatMovement";
import BotFlow from "./BotFlow/BotFlow";
import ReportsLiveDashboard from "./Reports/ReportsLiveDashboard";
import AdminLookupsCampusPage from "./Admin/AdminLookupsCampusPage";
import AdminLookupsRTPage from "./Admin/AdminLookupsRTPage";
import SettingsNewPage from "./Admin/SettingsNewPage";
import WhatsappTemplatesPage from "./Admin/WhatsappTemplatesPage";
import UCChooseBed from "../components/Applicant/UCChooseBed";
import ResourcesPage from "./Admin/ResourcesPage";
import AdminUploadsPage from "./Admin/AdminUploadsPage";
import ReportMoveInInspectionVariance from "./Reports/ReportMoveInInspectionVariance";
import ReportAtlasExpanded from "./Reports/ReportAtlasExpanded";

export default function App() {
	const {initialized} = useSelector((state) => state.async);
	const {current_staff} = useSelector((state) => state.auth);
	const location = useLocation();

	if (!initialized) return <AppLoader content="Loading app..."/>;

	//Redirect old link
	if (location.pathname === "/index.php/booking") {
		window.location.replace("/");
	}

	if (
		current_staff === null &&
		(location.pathname.includes("/booking") ||
			location.pathname.includes("/orders") ||
			location.pathname.includes("/reports") ||
			location.pathname.includes("/properties") ||
			location.pathname.includes("/settings") ||
			location.pathname.includes("/settings_users") ||
			location.pathname.includes("/edit/") ||
			location.pathname.includes("/roger/") ||
			location.pathname.includes("/meterreadings"))
	) {
		window.location.replace("/");
	}

	// let token = localStorage.getItem( "token" );
	if (process.env.REACT_APP_COLOUR === "UC") {
		document.title = "Urban Circle CRM";
	} else if (process.env.REACT_APP_COLOUR === "QL") {
		document.title = "Qliving CRM";
	} else if (process.env.REACT_APP_COLOUR === "QP") {
		document.title = "Quorum Properties CRM";
	}

	//GTM Tags
	if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1") {
		if (process.env.REACT_APP_COLOUR === "UC") {
			const tagManagerArgs = {
				gtmId: "GTM-NB5T78R",
			};
			TagManager.initialize(tagManagerArgs);
		} else if (process.env.REACT_APP_COLOUR === "QL") {
			const tagManagerArgs = {
				gtmId: "GTM-P8TZJGW",
			};

			TagManager.initialize(tagManagerArgs);
		}
	}

	return (
		<>
			<ModalManager/>
			<ToastContainer autoClose={2500} position="top-right" hideProgressBar/>

			{process.env.REACT_APP_COLOUR === "QL" &&
				<Route exact path={["/", "/edit_application/:url"]} component={ApplicationForm}/>}
			{/* {process.env.REACT_APP_COLOUR === "QL" && <Route exact path={["/v2", "/edit_application/:url/v2"]} component={ApplicationFormV2} />} */}

			{process.env.REACT_APP_COLOUR === "QP" &&
				<Route exact path={["/", "/edit_application/:url"]} component={ApplicationFormQP}/>}

			{/* {process.env.REACT_APP_COLOUR === "UC" && <Route exact path={["/", "/step1/:url"]} component={ApplicationFormUCStep1} />}
            {process.env.REACT_APP_COLOUR === "UC" && <Route path={["/step2/:url/:property_id/:type/:year/:gender/"]} component={ApplicationFormUCStep2} />}
            {process.env.REACT_APP_COLOUR === "UC" && <Route path={["/step3/:url/:property_id/:type/:year/:gender/:unit/:floor"]} component={ApplicationFormUCStep3} />} */}
			{process.env.REACT_APP_COLOUR === "UC" &&
				<Route exact path={["/", "/step1/:url"]} component={ApplicationFormUCStep1Short}/>}
			{process.env.REACT_APP_COLOUR === "UC" &&
				<Route path={["/step4/:url/:property_id/:type/:year/:gender/:unit/:bed/:waitbed"]}
					   component={ApplicationFormUCStep4}/>}
			{process.env.REACT_APP_COLOUR === "UC" && <Route path={["/choose-bed/"]} component={UCChooseBed}/>}

			{process.env.REACT_APP_COLOUR === "UC" &&
				<Route exact path={["/edit_application/:url"]} component={ApplicationFormUCEdit}/>}
			{/* {process.env.REACT_APP_COLOUR === "UC" && <Route exact path={["/edit_application/returning/:url"]} component={ApplicationFormUCEditReturning} />} */}
			{/* {process.env.REACT_APP_COLOUR === "UC" && <Route exact path={["/edit_application/choose_bed/:url"]} component={ApplicationFormUCEditReturning} />} */}

			{process.env.REACT_APP_COLOUR === "UC" && <Route exact path={["/payment/:url"]} component={PaymentFormUC}/>}

			{process.env.REACT_APP_COLOUR === "UC" && <Route exact path={["/payfast/:url"]} component={PayFastPageUC}/>}

			{process.env.REACT_APP_COLOUR === "UC" && <Route exact path={["/documents/:url"]} component={DocumentsUC}/>}
			{process.env.REACT_APP_COLOUR === "QL" && <Route exact path={["/documents/:url"]} component={DocumentsQL}/>}
			{process.env.REACT_APP_COLOUR === "UC" &&
				<Route exact path={["/bank-details/"]} component={BankDetailsUC}/>}
			{process.env.REACT_APP_COLOUR === "QL" &&
				<Route exact path={["/bank-details/"]} component={BankDetailsQL}/>}

			{process.env.REACT_APP_COLOUR === "UC" && <Route exact path={["/edit_nok/:url"]} component={NOKFormUC}/>}

			<Route path="/login" component={LoginGuestForm}/>

			<Route path="/adminlogin" component={LoginForm}/>
			<Route path="/forget-password" component={ForgetPassword}/>
			<Route path="/reset-password/:token" component={ResetPassword}/>
			{/* <Route path="/book-move-in/:url" component={MoveInScheduler} /> */}
			<Route path="/appointment/:type/:id/:action/:appointment_id/:url" component={MoveInScheduler}/>
			<Route path="/appointment_requested/:appointment_id/:manager_id" component={MoveInConfirm}/>

			{/* <Route path="" component={  } /> */}
			<Route path="/404" component={NotFound}/>

			{current_staff !== null && (
				<Route
					path={[
						"/booking",
						"/edit",
						"/settings",
						"/settings_users",
						"/meterreadings",
						"/properties",
						"/orders",
						"/reports",
						"/roger",
						"/roger",
						"/calendar",
						"/bot_flow",
						"/settings_new",
						"/admin_lookups",
						"/admin/*",
					]}
					render={() => (
						<>
							<TimeoutLogic/>
							<div className={"h-full flex flex-col " + process.env.REACT_APP_COLOUR}>
								<NavBar/>

								<main className="flex-1">
									<div className="ml-0 sm:ml-56 sm:pl-6 md:pl-8">
										<PrivateRoute path="/booking/:type" component={BookingGrid}/>
										{process.env.REACT_APP_COLOUR === "QP" &&
											<PrivateRoute path="/edit/:user_id/:booking_id" component={EditUserQP}/>}
										{process.env.REACT_APP_COLOUR === "QL" &&
											<PrivateRoute path="/edit/:user_id/:booking_id" component={EditUser}/>}
										{process.env.REACT_APP_COLOUR === "UC" &&
											<PrivateRoute path="/edit/:user_id/:booking_id" component={EditUserUC}/>}

										<PrivateRoute path="/meterreadings" component={MetersPage}/>
										<PrivateRoute path="/settings" component={Settings}/>
										<PrivateRoute path="/settings_users/:user_id" component={ManageUsers}/>
										<PrivateRoute path="/properties/step1" component={Properties1Page}/>
										<PrivateRoute path="/properties/step2/:property_id"
													  component={Properties2Page}/>
										<PrivateRoute path="/properties/step3/:property_id/:floor_id"
													  component={Properties3Page}/>

										<PrivateRoute path="/orders/landing" component={OrdersPage}/>
										<PrivateRoute path="/orders/pricing" component={OrdersPricing}/>

										<PrivateRoute path="/reports/landing" component={ReportsPage}/>
										<PrivateRoute path="/reports/emaillogs" component={ReportEmailLogs}/>
										<PrivateRoute path="/reports/exportall" component={ReportExportAll}/>
										<PrivateRoute path="/reports/applications" component={ReportApplications}/>
										<PrivateRoute path="/reports/exportbeds" component={ReportAllBeds}/>
										<PrivateRoute path="/reports/anomolies" component={ReportAnomolies}/>
										<PrivateRoute path="/reports/bedscmm" component={ReportBedsCMM}/>
										<PrivateRoute path="/reports/bedscolour" component={ReportBedsByColour}/>
										<PrivateRoute path="/reports/takeup" component={ReportTakeUp}/>
										<PrivateRoute path="/reports/user-tracking" component={ReportUserTracking}/>
										<PrivateRoute path="/reports/vendor" component={ReportVendor}/>
										<PrivateRoute path="/reports/leases" component={ReportsLeases}/>
										<PrivateRoute path="/reports/leases_signed" component={ReportsLeasesSigned}/>
										<PrivateRoute path="/reports/additional_invoices"
													  component={ReportsAdditionalInvoices}/>
										<PrivateRoute path="/reports/bank_details_changes"
													  component={ReportBankDetailsChange}/>
										<PrivateRoute path="/reports/chat_audit" component={ReportChatAudit}/>
										<PrivateRoute path="/reports/user_activity" component={ReportUserActivity}/>
										<PrivateRoute path="/reports/chat_movement" component={ReportChatMovement}/>
										<PrivateRoute path="/reports/cancellation" component={ReportsCancellation}/>
										<PrivateRoute path="/reports/move" component={ReportMove}/>
										<PrivateRoute path="/reports/mentions" component={ReportsMentions}/>
										<PrivateRoute path="/reports/viewings" component={ReportViewings}/>
										<PrivateRoute path="/reports/keywords" component={ReportKeyword}/>
										<PrivateRoute path="/reports/live_dashboard" component={ReportsLiveDashboard}/>
										<PrivateRoute path="/reports/move_in_inspection_variance"
													  component={ReportMoveInInspectionVariance}/>
										<PrivateRoute path="/reports/payments" component={ReportPayments}/>
										<PrivateRoute path="/reports/atlas_expanded" component={ReportAtlasExpanded}/>
										<PrivateRoute path="/reports/bed_allocation" component={ReportBedAllocation}/>

										<PrivateRoute path="/calendar/landing" component={BookingCalendar}/>

										<PrivateRoute path="/roger/emailfix" component={EmailFixPage}/>

										<PrivateRoute path="/bot_flow" component={BotFlow}/>
										<PrivateRoute path="/admin_lookups/campus" component={AdminLookupsCampusPage}/>
										<PrivateRoute path="/admin_lookups/building_room_types"
													  component={AdminLookupsRTPage}/>

										<PrivateRoute path="/settings_new" component={SettingsNewPage}/>
										<PrivateRoute path="/admin/whatsapp_templates"
													  component={WhatsappTemplatesPage}/>
										<PrivateRoute path="/admin/resources" component={ResourcesPage}/>
										<PrivateRoute path="/admin/uploads" component={AdminUploadsPage}/>
									</div>
								</main>
							</div>
						</>
					)}
				/>
			)}

			{/* <Route path="/scan" component={Scan} /> */}
		</>
	);
}
