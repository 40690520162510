import React, { useState, useEffect } from "react";
import axios from "axios";

export default function ATLASExpandedReport() {
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchAtlasReport = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}get-expanded-atlas-report`
                );
                setReportData(response.data);
            } catch (error) {
                console.error("Error fetching ATLAS report:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAtlasReport();
    }, []);

    const renderUnitTypeRow = (unitType) => (
        <tr>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-left">
                {unitType.unit_type}
            </td>
            <td className="px-3 py-4 text-sm text-gray-500">
                {unitType.unallocated}
            </td>
            <td className="px-3 py-4 text-sm text-gray-500">
                {unitType.lease_sent}
            </td>
            <td className="px-3 py-4 text-sm text-gray-500">
                {unitType.black}
            </td>
            <td className="px-3 py-4 text-sm text-gray-500">
                {unitType.pink}
            </td>
            <td className="px-3 py-4 text-sm text-gray-500">
                {unitType.blocked}
            </td>
            <td className="px-3 py-4 text-sm text-gray-500">
                {unitType.unallocated + unitType.lease_sent +
                    unitType.black + unitType.pink + unitType.blocked}
            </td>
        </tr>
    );

    const renderPropertyTable = (property) => (
        <div className="mb-8" key={property.property_id}>
            <h2 className="text-xl font-bold mb-2">{property.property_name}</h2>
            <table className="min-w-full divide-y divide-gray-300">
                <thead>
                <tr>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Unit Type
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Unallocated
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Lease Sent
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Signed Lease
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Moved In
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Blocked
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Total
                    </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                {property.unit_types.map((unitType, index) => (
                    renderUnitTypeRow(unitType)
                ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold text-center mb-6">
                Expanded ATLAS Report
            </h1>

            {loading ? (
                <div className="flex justify-center items-center">
                    <p className="text-center">Loading report...</p>
                </div>
            ) : (
                <div>
                    {reportData.map((property) => renderPropertyTable(property))}
                </div>
            )}
        </div>
    );
}